/**
 * 模块名称: 代理商积分管理
 * @author 徐小波
 */

import React from 'react'
import {Route, Switch} from 'react-router-dom'
import './assets/style.scss'
import Main from './Main'
import Details from './Details'
import Edit from './Edit'
import Add from './Add'

const AgentIntegral = ({match, history}) => {

    return (
        <Switch>
            <Route path={match.path + '/details'} component={Details}/>
            <Route path={match.path + '/add'} component={Add}/>
            <Route path={match.path + '/edit'} component={Edit}/>
            <Route component={Main} />
        </Switch>
    )
}

export default AgentIntegral