/**
 * 模块名称: 申请信用额度，调整界面
 * @author xuxiaobo@372163.com
 */

import React, {useEffect, useRef, useState} from 'react'
import {Col, Descriptions, Form, Icon, Input, message, Row, Select, Upload} from 'antd'
import api from '@/api'
import {parseSearch} from '@/utils'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'

const FormItem = Form.Item
const Option = Select.Option
const {Dragger} = Upload
const {TextArea} = Input
let describeUrl = []
let editId = 0 //编辑使用
let creditAccountData = {}
let accountType = '0';

const CreditApplyAdd = (props) => {
    const {location, history} = props
    const {getFieldDecorator, validateFields, resetFields, getFieldsError, setFieldsValue } = props.form
    const search = parseSearch(location.search)
    const [visibledraggerRef, ] = useState(false)
    const [visibleEdit4, setVisibleEdit4] = useState(false)
    const draggerRef = useRef(null)
    const [fileList, setFileList] = useState([])
    const [projects, setProjects] = useState([])
    const [saveLoading, setSaveLoading] = useState(false)
    const [accountTypeList, setAccountTypeList] = useState([])
    const [fundAccountList, setFundAccountList] = useState([])
    const [canEdit, ] = useState(true)

    useEffect(() => {
        let tmps = []
        tmps.push({id: '0', name: '长期'})
        tmps.push({id: '1', name: '临时'})
        setAccountTypeList(tmps)

        search.qId = search.qId ? search.qId : search.customerId

        //0发起申请,1发起调整
        var action = search.action ? search.action : 'apply'

        //0直销,1代理商
        var customerType = search.customerType ? search.customerType : '0'
        var customerId = search.customerId ? search.customerId : '0'

        //长期传0， 临时传1
        accountType = search.accountType ? search.accountType : accountType

        //驳回重提用
        var id = search.id ? search.id : '0'

        //0发起申请,1发起调整
        onEdit0({customerId: customerId, customerType: customerType, accountType: accountType, id: id})
    }, [])

    //发起申请
    const onEdit0 = async (date) => {
        setVisibleEdit4(true)
        resetFields()
        
        var customerType = date.customerType
        var customerId = date.customerId
        var id = date.id

        if (id > 0) {
            // console.log(1)
            //数据类型 0展示详情 1驳回重提详情
            await api.getCreditApplyDetail({id: id, type: 1}, true).then(data => {
                try {
                    describeUrl = JSON.parse(data.describeUrl)
                } catch (e) {
                }
                try {
                    describeUrl = JSON.parse(JSON.parse(data.describeUrl))
                } catch (e) {
                }
                editId = data.id
                creditAccountData = data
                // creditAccountData.projectIds = creditAccountData.projectIds.split(',')
                creditAccountData.oaqId = data.oaqId
                creditAccountData.accountTypeName = data.accountTypeName
                creditAccountData.accountType = data.accountType + ''
                //console.log(creditAccountData)

                if (describeUrl.length) {
                    describeUrl.forEach((item, index) => {
                        fileList.push({
                            status: 'done',
                            uid: index,
                            name: item.name,
                            url: item.url
                        })
                    })
                }
                // console.log(describeUrl, fileList)
                setFileList(describeUrl.map((item, index) => ({...item, uid: index})))

                api.creditAdjustDetail({id: creditAccountData.id}).then(data => {
                    creditAccountData.trueAmount = data.amount //信用额度
                })
            })

            await api.getCusTomInfo({relationType: customerType, relationId: customerId}).then(data => {
                creditAccountData.cqname = data.relationName
                creditAccountData.ctype = data.relationType
            })

            await api.getTopProjectList({isOpen: 1, companyId: creditAccountData.companyId }).then(data => {
                setProjects(data.list)
            })

            //type=1资金账户 2积分账户
            await api.getFundAccountList({relationType: customerType, customerId: customerId, type: 1}).then(res => {
                let data = res.list
                for (var i = 0; i < data.length; i++) {
                    data[i].id = data[i].companyId
                    data[i].name = data[i].companyName
                }
                setFundAccountList(data)
                // console.log("resArry",resArry)
                // for (var i = 0; i < data.length; i++) {
                //     data[i].id = data[i].companyId
                //     data[i].name = data[i].companyName
                // }
                // setFundAccountList(data)
            })
        } else {
            // console.log(0)
            editId = customerId
            creditAccountData = date
            creditAccountData.oaqId = customerType == 0 ? customerId : search.qId
            creditAccountData.accountTypeName = date.accountType == '0' ? '长期' : '临时'
            //console.log(creditAccountData)

            await api.getCusTomInfo({relationType: customerType, relationId: customerType == 0 ? customerId : search.qId}).then(data => {
                creditAccountData.cqname = data.relationName
                creditAccountData.ctype = data.relationType
            })

            // await api.getTopProjectList({isOpen: 1}).then(data => {
            //     console.log(22222)
            //     setProjects(data.list)
            // })

            //type=1资金账户 2积分账户
            await api.getFundAccountList({relationType: customerType, customerId: customerType == 0 ? customerId : search.qId, type: 1}).then(res => {
                let data = res.list
                for (var i = 0; i < data.length; i++) {
                    data[i].id = data[i].companyId
                    data[i].name = data[i].companyName
                }
                setFundAccountList(data)
            })
        }
    }

    // 保存，申请
    const formSubmits0 = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {

            if (!err) {
                setSaveLoading(true)
                var tmpdata = {
                    id: creditAccountData.id,
                    oaqId: creditAccountData.oaqId,
                    cqname: creditAccountData.cqname,
                    companyId: vals.edit_accountId ? vals.edit_accountId : 0,
                    accountType: vals.edit_accountType,
                    amount: vals.edit_trueAmount,
                    ctype: creditAccountData.ctype,
                    startDay: vals.edit_startDay,
                    contractNo: vals.edit_contractNo,
                    projectIds: vals.edit_projectid,
                    describeUrl: fileList,
                    remark: vals.edit_remark,
                }

                api.creditAccountApply(tmpdata)
                    .then(() => {
                        setSaveLoading(false)
                        setVisibleEdit4(false)
                        message.success('操作成功')
                        setTimeout(() => {
                            history.push('/account/creditapply')
                        }, 1000);
                    })
                    .catch(() => setSaveLoading(false))
            } else {
                let errors = getFieldsError();
                for (let n in errors) {
                    if (errors[n] != undefined) {
                        message.error(errors[n][0]);
                        return;
                    }
                }
            }
        })
    }

    // 文件上传
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .pptx, .pdf',
        allowSizeType: 2,
        limitNum: 10
	})

    const projectsOption = projects.map((project) => (
        <Option key={project.id} value={project.id}>{project.name}</Option>
    ))

    const changeAccountType = (value) => {
        accountType = value;
    }

    //选择账户时
    const onSelectCompany = (id) => {
        setFieldsValue({ edit_projectid: undefined })
        api.getTopProjectList({isOpen: 1, companyId: id }).then(data => {
            setProjects(data.list)
        })
    }

     //取消
    const onCancel = () => {
        setVisibleEdit4(false)
        history.goBack()
    }

    return (
        <>
            <div className="pro-detail">
                <Descriptions title={search.id > 0 ? '申请信用额度驳回重提' : '申请信用额度'} layout="horizontal" className="pro-detail-title"></Descriptions>
                {
                    visibleEdit4 ? <Form onSubmit={formSubmits0}>

                        <Row gutter={24}>
                            <Col span={6}>
                                <FormItem label="OAID/QID:">{creditAccountData.oaqId}</FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="客户/代理商名称">{creditAccountData.cqname}</FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="账户" colon={true}>
                                    {getFieldDecorator('edit_accountId', {
                                        initialValue: creditAccountData.companyId,
                                        rules: [{required: true, message: '请输入账户'}]
                                    })(
                                        <Select placeholder="请选择" onSelect={onSelectCompany}>
                                            {fundAccountList && fundAccountList.map(item => <Option key={item.id}
                                                                                 value={item.id}>{item.name}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="账户类型">
                                    {getFieldDecorator('edit_accountType', {
                                        initialValue: creditAccountData.accountType,
                                        rules: [{required: true, message: '请输入账户类型'}]
                                    })(
                                        <Select placeholder="请选择" onChange={(value) => changeAccountType(value)}>
                                            {accountTypeList.map(item => <Option key={item.id}
                                                                                 value={item.id}>{item.name}</Option>)}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={6}>
                                <FormItem label="信用额度">
                                    {getFieldDecorator('edit_trueAmount', {
                                        initialValue: creditAccountData.trueAmount,
                                        rules: [{ required: true, message: '请输入信用额度' }, { pattern: /^[0-9]{1,8}([.][0-9]{2})?$/, message: '最大值9000万的整数' }]
                                    })(
                                        <Input placeholder="请输入信用额度"/>)}
                                </FormItem>
                            </Col>
                            {/* {accountType == 1 && } */}
                            <Col span={6}>
                                <FormItem label="使用期限（天）">
                                    {getFieldDecorator('edit_startDay', {
                                        initialValue: creditAccountData.startDay,
                                        rules: [{required: true, message: '请输入使用期限'},{
                                        // rules: [{required: accountType == 1 ? true : false, message: '请输入使用期限'},{ 
                                          pattern: /^[1-9]\d*$/, message: '请输入正整数'
                                        }]
                                    })(
                                        <Input
                                            placeholder="请输入使用期限"/>)}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="合同号">
                                    {getFieldDecorator('edit_contractNo', {initialValue: creditAccountData.contractNo})(
                                        <Input
                                            placeholder="请输入合同号"/>)}
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="关联项目">
                                    {getFieldDecorator('edit_projectid', {
                                        initialValue: creditAccountData.projectIds,
                                        rules: [{required: true, message: '请输入关联项目'}]
                                    })(
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            placeholder="请选择关联项目"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}>
                                            {projectsOption}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <FormItem label="信用账户ID">未生成</FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem label="信用账户生效日期">未生成</FormItem>
                            </Col>
                            {accountType == 1 && <Col span={6}>
                                <FormItem label="信用账户失效日期">未生成</FormItem>
                            </Col>}
                        </Row>
                        {
                            accountType == 1 && <p style={{ color: 'red', fontSize: '12px', fontWeight: 500 }}>
                                提示：新申请临时信用额度失效日期 = 该临时信用额度新申请审批通过日期 + 使用期限 +1，该临时信用额度关联的信用订单逾期日 = 该信用订单生效日期 + 使用期限 +1
                            </p>
                        }                 
                        <Descriptions title="" layout="vertical">
                            <Descriptions.Item label="附件" visible={visibledraggerRef}>
                                <div className="dragger-box" ref={draggerRef}>
                                    <Dragger
                                        {...uploadFiles}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox"/>
                                        </p>
                                        <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                        <p className="ant-upload-hint">
                                            支持扩展名：.gif、png、jpg、pptx、pdf 单个文件大小不超过5M，不超过5个附件。
                                        </p>
                                    </Dragger>
                                </div>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions title="" layout="vertical">
                            <Descriptions.Item label="备注">
                                {getFieldDecorator('edit_remark', {initialValue: creditAccountData.remark})(
                                    <TextArea disabled={!canEdit} cols={150} rows={4}></TextArea>
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                        <BtnGroup onCancel={onCancel} loading={saveLoading}/>
                    </Form> : <div></div>
                }
            </div>
        </>
    )
}

export default Form.create()(CreditApplyAdd)