/**
 * 模块名称: 年度目标管理
 * @author yangzihang@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from 'api'
import {
  Table,
  Form,
  Drawer,
  DatePicker,
  Input,
  Button,
  Alert,
  message,
  Switch,
  Select,
  Modal,
} from 'antd';
import Auth from 'components/AuthMiddleware'
import { parseSearch } from 'utils'
import EditResult from './module/EditResult'
import Import from '@/components/Import'
import ImportResult from './module/ImportResult'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM'

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage
}
let intervalRef = {}

const AchResult = props => {

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const { history, match, location } = props
  const search = parseSearch(location.search)

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [editVisible, setEditVisible] = useState(false)
  const [editData, setEditData] = useState({})
  const [authList, setAuthList] = useState([])//按钮权限
  const [checked, setChecked] = useState(0)
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [assessList, setAssessList] = useState([])
  const [departLeadList, setDepartLeadList] = useState([])
  const [departList, setDepartList] = useState([])
  const [groupList, setGroupList] = useState([])
  const [leadList, setLeadList] = useState([])
  const [isopen, setIsopen] = useState(false)
  const [visibleFile, setVisibleFile] = useState(false)
  const [visibleResult, setVisibleResult] = useState(false)
  const [importType, setImportType] = useState(1)
  const [monthList, setMonthList] = useState([])
  const ImportRef = useRef()
  const ImportResultRef = useRef()

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getList()
    getPageAuth()
    getSearchData()
    return () => {
      for(let i in intervalRef){//eslint-disable-line
        clearTimeout(intervalRef[i])
      }
    }
  }, [])
  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取列表
  const getList = () => {
    intervalRef.current = setTimeout(() => {
      api.getAchResultList(searchParamsTmp).then(res => {
        setList(res.list)
        setCount(res.count)
        setLoading(false)
        clearTimeout(intervalRef.current)
      }).catch(() => { setLoading(false) })
    }, 500)
  }

  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getList()
  }
  //提交
  const formSubmit = evt => {
    evt.preventDefault()
    validateFields((err, vals) => {
      if(vals !== undefined){
        if(vals.staffName !== undefined)searchParamsTmp.staffName = vals.staffName
        if(vals.staffNo !== undefined)searchParamsTmp.staffNo = vals.staffNo
        if(vals.assessItems !== undefined)searchParamsTmp.assessItems = vals.assessItems
        if(vals.departLeadId !== undefined)searchParamsTmp.departLeadId = vals.departLeadId
        if(vals.groupId !== undefined)searchParamsTmp.groupId = vals.groupId
        if(vals.departmentId !== undefined)searchParamsTmp.departmentId = vals.departmentId
        if(vals.leaderId !== undefined)searchParamsTmp.leaderId = vals.leaderId

        if(vals.month !== undefined){
          searchParamsTmp.startMonth = vals.month ? vals.month[0].format(dateFormat) : ''
          searchParamsTmp.endMonth = vals.month ? vals.month[1].format(dateFormat) : ''
        }
      }
      if(!err){
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getList()
      }
    })
  }
  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  // 编辑
  const onEdit = data => {
    setEditData(data)
    setEditVisible(true)
  }

  const onDelete = () => {
    if (checkedIdArr.length === 0) {
      message.success('请先勾选删除项')
      return
    }
    Modal.confirm({
      content: '请确认是否删除？',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        api.delAchResult({
          id: checkedIdArr
        }).then(() => {
          message.success('删除成功')
          setCheckedIdArr([])
          setChecked(0)
          getList()
        })
      }
    })
  }

  const rowSelection = {
    onSelect: (record, selected) => {
      if (!selected) {
        checkedIdArr.splice(checkedIdArr.indexOf(record.id), 1);
      } else {
        checkedIdArr.push(record.id)
      }
      updateSelected(checkedIdArr)
    },

    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        changeRows.map(item => checkedIdArr.push(item.id))
      } else {
        changeRows.map(item => checkedIdArr.splice(checkedIdArr.indexOf(item.id), 1))
      }
      updateSelected(checkedIdArr)
    },
    selectedRowKeys: checkedIdArr
  }

  const updateSelected = (checkedIdArr) => {
    setCheckedIdArr(checkedIdArr)
    setChecked(checkedIdArr.length)
  }

  //改变标记
  const onChangeSign = (record, val) => {
    api.signAchResult({
      staffId: record.staffId,
      month: record.month,
      sign: val ? 1 : 0
    }).then(() => {
      getList()
    })
  }

  //获取检索所需数据
  const getSearchData =  () => {
    api.getSearchData().then(res => {
      const { assessItemsList, leadList, groupList, departList, departLeadList } = res
      setAssessList(assessItemsList)
      setDepartLeadList(departLeadList)
      setDepartList(departList)
      setGroupList(groupList)
      setLeadList(leadList)
    })
  }
  // 获取有数据的月份列表
  const getMonthList =  () => {
    api.getMonthList().then(res => {
      setMonthList(res)
    })
  }

  // 弹出日历和关闭日历的回调
  const handleOpenChange = status => setIsopen(status)
  // 拿到value并进行处理
  const handlePanelChange = value => {
      setIsopen(false)
      setFieldsValue({ month: value })
  }

  // 导出
  const exportFile = () => {
    api.getHost().then(res => {
      let args = JSON.parse(JSON.stringify(searchParamsTmp))
      if (checkedIdArr.length > 0) {
        args.id = checkedIdArr
      }
      delete args.limit
      delete args.page
      let searchParams = new URLSearchParams();
      for (let key in args) {
        if (args[key] && args[key] !== '') {
          searchParams.set(key, args[key]);
        }
      }
      window.location = `${res.url}/api/fortune/achievements/exportAchResult?${searchParams.toString()}`
    })
  }

  //导入
  const onImportFile = (type) => {
    setImportType(type)
    if (type === 1) {
      setVisibleFile(true)
      ImportRef.current.onRemoved()
    } else {
      getMonthList()
      setVisibleResult(true)
      ImportResultRef.current.onRemoved()
    }
  }

  const onCloseImport = () => {
    setVisibleFile(false)
    setVisibleResult(false)
  }

  const doImport = (fileList, month) => {
    if (fileList.length > 0) {
      if (fileList[0].status !== "removed") {
        const { full, name, url } = fileList[0]
        const params = {
          type: importType,
          fullPath: full,
          fileName: name,
          relativePath: url,
        }
        if (importType === 2) {
          params.month = month
        }
        api.uploadAchResultFile(params).then(() => {
          message.success('导入成功')
          if (importType === 1) {
            ImportRef.current.onLoading()
            setVisibleFile(false)
          } else {
            ImportResultRef.current.onLoading()
            setVisibleResult(false)
          }
          getList()
        }).catch(() => {
          if (importType === 1) {
            ImportRef.current.onLoading()
          } else {
            ImportResultRef.current.onLoading()
          }
        })
      } else {
        message.error('请导入模板')
      }
    } else {
      message.error('请导入模板')
    }
  }

  const expandedRowRender = record => {
    return (
      <Table
        size="small"
        dataSource={record.targList}
        rowKey="id"
        pagination={false}
      >
        <Column title="考核项" dataIndex="assessItems" width={'20%'} />
        <Column title="考核权重" dataIndex="weight" width={'20%'} />
        <Column title="考核目标" dataIndex="target" width={'20%'} />
        <Column title="完成结果" dataIndex="result" width={'20%'} />
        <Column title="完成率" dataIndex="completionRate" width={'20%'} />
      </Table>
    )
  }

  return <>
    <div className="search-doc-wrap">
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <div className="form-box">
          <FormItem label="姓名">
            {getFieldDecorator('staffName')(<Input placeholder="请输入姓名" />)}
          </FormItem>
          <FormItem label="员工号">
            {getFieldDecorator('staffNo')(<Input type="number" placeholder="请输入员工号" />)}
          </FormItem>
          <FormItem label="部门负责人">
            {getFieldDecorator('departLeadId')(<Select 
              placeholder="请选择"
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                departLeadList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)
              }
            </Select>)}
          </FormItem>
          <FormItem label="绩效月份">
            {getFieldDecorator('month')(<RangePicker
              open={isopen}
              placeholder={['开始月份', '结束月份']}
              format={dateFormat}
              mode={['month', 'month']}
              onOpenChange={handleOpenChange}
              onPanelChange={handlePanelChange}
            />)}
          </FormItem>
          <FormItem label="所属事业群">
            {getFieldDecorator('groupId')(<Select 
              placeholder="请选择"
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                groupList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)
              }
            </Select>)}
          </FormItem>
          <FormItem label="所属部门">
            {getFieldDecorator('departmentId')(<Select 
              placeholder="请选择"
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                departList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)
              }
            </Select>)}
          </FormItem>
          <FormItem label="直接主管">
            {getFieldDecorator('leaderId')(<Select 
              placeholder="请选择"
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                leadList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)
              }
            </Select>)}
          </FormItem>
          <FormItem label="考核项">
            {getFieldDecorator('assessItems')(<Select 
              placeholder="请选择"
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                assessList.map((item, index) => <Option key={index} value={item}>{item}</Option>)
              }
            </Select>)}
          </FormItem>
        </div>
        <FormItem className="btns" label=" " colon={false}>
          <Button type="primary" htmlType="submit">查询</Button>
          <Button onClick={onReset}>重置</Button>
        </FormItem>
      </Form>
    </div>
    <div className="line"></div>
    <div>
      <div className="add-wrap">
        <Auth auths={authList} code="delete">
          <Button type="primary" icon="delete" onClick={onDelete}>批量删除</Button>
        </Auth>
        <Auth auths={authList} code="export">
          <Button type="primary" icon="upload" onClick={exportFile} style={{ marginLeft: 15 }}>导出</Button>
        </Auth>
        <Auth auths={authList} code="import">
          <Button type="primary" icon="download" onClick={() => onImportFile(1)} style={{ marginLeft: 15 }}>导入目标</Button>
          <Button type="primary" icon="download" onClick={() => onImportFile(2)} style={{ marginLeft: 15 }}>导入结果</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"  
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
        expandedRowRender={expandedRowRender}
      >
        <Column title="绩效月份" dataIndex="month" />
        <Column title="姓名" dataIndex="staffName" />
        <Column title="员工号" dataIndex="staffNo" />
        <Column title="职位" dataIndex="jobPosition" />     
        <Column title="直接主管" dataIndex="leaderName" />
        <Column title="部门负责人" dataIndex="depleadName" />
        <Column title="所属事业群" dataIndex="groupName" />
        <Column title="所属部门" dataIndex="departmentName" />
        <Column title="部门类型" dataIndex="departmentType" />
        <Column title="绩效结果" dataIndex="assessResult" />
        <Column 
          title="标记" 
          dataIndex="sign" 
          render={(text, record) => <Switch size="small" checked={text == 1} onChange={val => onChangeSign(record, val)} />} />
        <Column 
          title="操作"
          key="set" 
          align="center"
          width={80}
          render={(text, record) =>
            <>
              <Auth auths={authList} code="edit">
                <a onClick={() => onEdit(record)}>编辑</a>
              </Auth>
            </>
          }
        />
      </Table>
    </div>
    <Drawer
      title="编辑"
      width={600}
      onClose={() => setEditVisible(false)}
      visible={editVisible}
      destroyOnClose={true}
    >
      <EditResult data={editData} setVisible={setEditVisible} getList={getList} />
    </Drawer>
    <Import
      title="导入目标数据"
      onSearch={doImport}
      visibleFile={visibleFile}
      onCloseImport={onCloseImport}
      ImportTemplate="/api/fortune/achievements/exportTargetTmp"
      ImportRef={ImportRef}
      accept=".xls, .xlsx"
    />
    <ImportResult
      title="导入结果数据"
      importFn={doImport}
      visibleFile={visibleResult}
      onCloseImport={onCloseImport}
      ImportTemplate="/api/fortune/achievements/exportResultTmp"
      ImportRef={ImportResultRef}
      monthList={monthList}
    />
  </>;
}

export default Form.create()(AchResult)
