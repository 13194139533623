/**
 * 模块名称: 信用额度审批详情页
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Steps,
  Button,
  Modal,
  Input,
  Table,
  Icon,
  message
} from 'antd'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table

let describeUrl = []
let addType = 0
let total1 = 0
let total2 = 0

const CreditApplyDetail = (props) => {
  const { location, history } = props
  const search = parseSearch(location.search)
  const [detailData, setDetailData] = useState({})
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [buttonAuthList, setButtonAuthList] = useState({})
  const [detailDataId, setdetailDataId] = useState('')
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [affixList, setAffixList] = useState([])
  const [reviewLoading, setReviewLoading] = useState(false) // 审批确定loading
  const [delay, ] = useState(3000)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
    if (plaintext && plaintextTwo && +search.time) { 
      if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
        api.getCreditApplyDetail({ id: search.id, type: 0 }, true).then(data => {
          if (search.msgId) {
            api.setMessageReaded({ ids: [search.msgId] })
          }
          dealCreditData(data)
        })
      } else {
        message.error('没有本页访问权限')
        setTimeout(() => {
          history.go(-2)
        }, 1000)
      }
    }
  }, [])

  //返回的数据有时候是" "包裹的json，有的时候是""  ""两层引号包裹的json，有时候直接返回json，数据有问题
  const dealCreditData = data => {
    if(typeof data.describeUrl == 'object'){
      describeUrl = data.describeUrl
    }else if(typeof JSON.parse(data.describeUrl) == 'string'){
      describeUrl = JSON.parse(JSON.parse(data.describeUrl))
    }else if(typeof JSON.parse(data.describeUrl) == 'object'){
      describeUrl = JSON.parse(data.describeUrl)
    }else{
      describeUrl = []
    }

    setAffixList(describeUrl)
    setDetailData(data)
    addType = data.addType //0信用申请，1信用调整
    total1 = data.total[0]
    total2 = data.total[1]

    let tmplist = []
    tmplist.push(data)
    setList(tmplist)
    setCount(tmplist.length)
    
    setdetailDataId(data.id)
    setStatusList(data.audit_flow ? data.audit_flow : []) // 审批进度
    setLogConList(data.audit_log) // 审批日志
    setButtonAuthList(data.auditAuth) // 审批权限
  }

  //审批类型 0通过 1驳回 2转上级 3补充资料
  // 转上级
  const onTurnUp = () => {
    api.creditApplyAudit({
      id: detailDataId,
      type: 2,
      remark: ''
    }).then(data => {
      history.push('/account/creditapply')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.creditApplyAudit({
      id: detailDataId,
      type: 3,
      remark: ''
    }).then(data => {
      history.push('/account/creditapply')
    }).catch()
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }
    setReviewLoading(true)
    //审批类型 0通过 1驳回 2转上级 3补充资料
    api.creditApplyAudit({
      id: detailDataId,
      type: 1,
      remark: textAreaVal
    }).then(() => {
      api.getCreditApplyDetail({ id: search.id, type: 0 }, true).then(data => {
        setReviewLoading(false)
        dealCreditData(data)
      })
    }).catch(() => setReviewLoading(false))
    setTurnModalVisible(false)
  }
  
  // 通过
  const onBtnPassShow = () => {
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    //审批类型 0通过 1驳回 2转上级 3补充资料
    setReviewLoading(true)
    api.creditApplyAudit({
      id: detailDataId,
      type: 0,
      remark: pasDesc
    }).then(() => {
      api.getCreditApplyDetail({ id: search.id, type: 0 }, true).then(data => {
        setReviewLoading(false)
        dealCreditData(data)
      })
    }).catch(() => setReviewLoading(false))
    setModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  return (
    <>
      <div className="pro-detail account-credit-detail">
        <Descriptions title="信用额度审批详情" layout="horizontal" className="account-credit-title" style={{borderBottom: 'none'}}>
          <dItem label="业务编号">
            <>
              {detailData.creditNo}
            </>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                  }
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>
        
        {addType == 1 ? 
        <Descriptions title="客户信息" layout="vertical" column={4} className="pro-detail-smailtitle">
        <Descriptions.Item label="OAID/QID">{detailData.oaqId}</Descriptions.Item>
        <Descriptions.Item label="客户名称/代理商名称">{detailData.cqname}</Descriptions.Item>
        <Descriptions.Item label="账户类型">{detailData.accountTypeName}</Descriptions.Item> 
        <Descriptions.Item label="现信用额度">{detailData.oldAmount ? detailData.oldAmount : 0}</Descriptions.Item>
        </Descriptions>
        : 
        <Descriptions title="客户信息" layout="vertical" column={4} className="pro-detail-smailtitle">
          <Descriptions.Item label="OAID/QID">{detailData.oaqId}</Descriptions.Item>
          <Descriptions.Item label="客户名称/代理商名称">{detailData.cqname}</Descriptions.Item>
          <Descriptions.Item label="账户类型">{detailData.accountTypeName}</Descriptions.Item>
          <Descriptions.Item label="信用额度">{detailData.amount ? detailData.amount : 0}</Descriptions.Item>
        </Descriptions>
        }

        {addType == 1 ? 
        <Descriptions title="" layout="vertical" column={4}>
        <Descriptions.Item label="信用额度调整为(增量)">{detailData.amount ? `${detailData.amount}（${detailData.addAmount}）` : 0}</Descriptions.Item>
        <Descriptions.Item label="现关联项目">{detailData.oldProject}</Descriptions.Item>
        <Descriptions.Item label="关联项目调整为">{detailData.project}</Descriptions.Item>
        <Descriptions.Item label="该客户信用总额">
            {detailData.sumAomut} (直销额度：{total1}，渠道额度：{total2 ? total2 : 0})
        </Descriptions.Item>
        </Descriptions>
        : 
        <Descriptions title="" layout="vertical" column={4}>
          <Descriptions.Item label="关联项目">{detailData.project}</Descriptions.Item>
          <Descriptions.Item label="客户信用总额" className="red">
              {detailData.sumAomut} (直销额度：{total1}，渠道额度：{total2 ? total2 : 0})
          </Descriptions.Item>
          <Descriptions.Item label="账户">{detailData.company}</Descriptions.Item>
          <Descriptions.Item label="合同号">{detailData.contractNo}</Descriptions.Item>
        </Descriptions>
        }

        <Descriptions title="" layout="vertical" column={4}>
          {addType == 1 ? <Descriptions.Item label="账户">{detailData.company}</Descriptions.Item> : null}
          {detailData.startDay !== '' ?
            <Descriptions.Item label="使用期限">{detailData.startDay}天</Descriptions.Item>
          : null}
          <Descriptions.Item label="信用账户ID">{detailData.accountId ? detailData.accountId : '未生成'}</Descriptions.Item>
          <Descriptions.Item label="信用账户生效日期">{detailData.effectTime ? detailData.effectTime : '未生成'}</Descriptions.Item>
          {detailData.accountType == 1 && <Descriptions.Item label="信用账户失效日期">{detailData.endTime ? detailData.endTime : '未生成'}</Descriptions.Item>}
        </Descriptions>
        {
          detailData.accountType == 1 && (
            addType == 1 ? (
              <p style={{ color: 'red', fontSize: '12px', fontWeight: 500, marginTop: '10px' }}>
                提示：临时信用额度调额后失效日期 = 该临时信用额度调额审批通过的日期 + 使用期限 +1，该临时信用额度关联的信用订单逾期日 = 该信用订单生效日期 + 使用期限 +1
              </p>
            ) : (
              <p style={{ color: 'red', fontSize: '12px', fontWeight: 500, marginTop: '10px' }}>
                提示：新申请临时信用额度失效日期 = 该临时信用额度新申请审批通过日期 + 使用期限 +1，该临时信用额度关联的信用订单逾期日 = 该信用订单生效日期 + 使用期限 +1
              </p>
            )
          )
        }
        <div className="line-box"></div>
        
        <Descriptions title="其他信息" layout="vertical" className="pro-detail-smailtitle">
          <dItem label="附件" span={3}>
          <div className="content">
            {
                affixList.length ? affixList.map((item, index) => {
                    return <div key={index}><a href={item.url} target="_blank"
                                                rel="noopener noreferrer">{item.name}</a></div>
                }) : '无'
            }
          </div>
          </dItem>
          <dItem label="备注">
            <>
              {detailData.remark ? detailData.remark : '无'}
            </>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Table
          dataSource={logConList}
          rowKey="id"
          pagination={false}
        >
          <Column title="申请人/审批人" dataIndex="userInfo.staffName" />
          <Column title="审批时间" dataIndex="addTime" />
          <Column title="状态" dataIndex="status" />
          <Column title="备注" dataIndex="chargeMsg" />
        </Table>

        <div className="btn-setting">
          <Button hidden={!buttonAuthList.replenish} onClick={onReplenish}>补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={onTurnUp}>转上级</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          <Modal
            title={turnNode()}
            // title="确认驳回"
            visible={turnModalVisible}
            onOk={onTurnApprove}
            onCancel={onTurnCancel}
            footer={[
              <Button key="back" onClick={onTurnCancel}>取消</Button>,
              <Button key="submit" type="primary" loading={reviewLoading} onClick={debounce(() => {onTurnApprove()}, delay)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>
          <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={onApprove}
            onCancel={onPassCancel}
            footer={[
              <Button key="back" onClick={onPassCancel}>取消</Button>,
              <Button key="submit" type="primary" loading={reviewLoading} onClick={debounce(() => {onApprove()}, delay)}>确定</Button>
            ]}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
            </div>
          </Modal>
        </div>
        
      </div>
    </>
  )
}

export default CreditApplyDetail;