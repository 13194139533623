/**
 * 模块名称: 信用账户列表
 * @author xuxiaobo@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Descriptions,
  Tabs
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import moment from 'moment'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
const { TabPane } = Tabs
let editId = 0 //编辑使用
let oaqId = 0
let pageSize = 10
let currentPage = 1
let pageSize2 = 10
let currentPage2 = 1
let type = 0
let id = 0

let filter = {}

const CreditAccount = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [detailList, setDetailList] = useState([])
  const [detailCount, setDetailCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const search = parseSearch(location.search)
  const [searchShow, setSearchShow] = useState(false)
  const [detailShow1, setDetailShow1] = useState(false)
  const [detailShow2, setDetailShow2] = useState(false)
  const [projectList, setProjectList] = useState([])
  const [accountType, setAccountType] = useState('')
  const [sproposerIdVal, setSproposerId] = useState('')
  const [dataVal, setDataVal] = useState({})
  const [companyId, setCompanyId] = useState({})
  const [creditAcount, setCreditAcount] = useState([])
  const [creditAcountCount, setCreditAcountCount] = useState(0)
  const [coltypeList, setColtypeList] = useState([])
  const [activeKey, setActiveKey] = useState('1')


  useEffect(() => {
    // currentPage = search.page ? +search.page : 1
    // pageSize = search.limit ? +search.limit : 10
    type = search.type ? search.type : 0
    id = search.id ? search.id : 0
    getList()
    getProjectList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    setLoading(true)
    api.getCreditAccountList({
      ...filter,
      limit: global.paramsLimit,
      page: currentPage,
      type: type,
      id: id,
      accountType: search.accountType ? search.accountType : '',
      companyId: search.accountId ? search.accountId : ''
    }).then(data => {
      setLoading(false)
      setList(data.list)
      setCount(data.count)
    }).catch(() => setLoading(false))
  }

  const getProjectList = () => {
    api.getsysProjectChilds({ pid: 0 }).then(data => {
      setProjectList(data)
    })
  }

  //详情
  const onDetail = (date) => {
    setActiveKey('1')
    setSearchShow(true)
    setDetailShow1(true)
    setDetailShow2(true)
    setAccountType(date.accountType)
    setDataVal(date)
    resetFields()
    filter.appliedateMin = ''
    filter.appliedateMax = ''
    filter.project = ''
    filter.number = ''
    filter.vendor = ''
    editId = date.id
    oaqId = date.oaqId
    setSproposerId(date.sproposerId)
    setCompanyId(date.companyId)

    api.getCreditarrearsList({ ...filter, limit: pageSize, page: currentPage, id: date.id, sproposerId: date.sproposerId, type: type, oaqId: date.oaqId, creditType: date.accountType, companyId: date.companyId }).then(data => {
      setLoading(false)
      setDetailList(data.list)
      setDetailCount(data.count)
    })
  }

  //调整  (注意：trueAmount取得值是totalAmount总额度)
  const onEdit = async (date) => {
    var type = date.type ? date.type : '0'
    history.push('/account/creditapply/edit?customerType=' + type + '&customerId=' + date.oaqId + '&accountType=' + date.accountType + '&company=' + date.company + '&trueAmount=' + date.totalAmount + '&usedAmount=' + date.usedAmount + '&accountId=' + date.id + '&companyId=' + date.companyId + '&ids=' + date.id)
    return false
  }

  // 查询
  const onSearch = (e) => {
    setSearchShow(true)
    setDetailShow1(true)
    setDetailShow2(true)
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log('filter', filter)
      // console.log('vals', vals)
      if (!err) {
        filter = { 
          appliedateMax: filter.appliedateMax,
          appliedateMin: filter.appliedateMin,
          number: vals.number ? vals.number : '',
          project: vals.project ? vals.project : '',
          vendor: vals.vendor ? vals.vendor : ''
        }
        currentPage = 1
        history.replace(match.path + '?id=' + search.id + '&type=' + search.type)
        api.getCreditarrearsList({
          ...filter, limit: pageSize, page: currentPage, id: editId, sproposerId: sproposerIdVal, type: type, oaqId: oaqId, creditType: accountType, companyId: companyId
        })
          .then(data => {
            setLoading(false)
            setDetailList(data.list)
            setDetailCount(data.count)
          })
      }
    })
  }

  const onSearch2 = (e) => {
    setSearchShow(true)
    setDetailShow1(true)
    setDetailShow2(true)
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log('filter', filter)
      // console.log('vals', vals)
      if (!err) {
        filter = { 
          endDate: filter.endDate,
          startDate: filter.startDate,
          number: vals.number ? vals.number : '',
          project: vals.project ? vals.project : '',
          vendor: vals.vendor ? vals.vendor : '',
          coltype: vals.coltype ? vals.coltype : ''
        }
        currentPage = 1
        history.replace(match.path + '?id=' + search.id + '&type=' + search.type)
        setLoading(true)
        api.creditAcountgetDetailed({
          accountId: dataVal.id,
          limit: pageSize2, 
          page: currentPage2,
          ...filter
        }).then(res =>{
          setLoading(false)
          setCreditAcount(res.list)
          setCreditAcountCount(res.count)
        })
      }
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    // history.replace(match.path + '?id=' + search.id + '&type=' + search.type + '&page=' + currentPage + '&limit=' + pageSize)
    api.getCreditarrearsList({ ...filter, limit: pageSize, page: currentPage, id: dataVal.id, sproposerId: dataVal.sproposerId, type: type, oaqId: dataVal.oaqId, creditType: dataVal.accountType, companyId: companyId }).then(data => {
      setLoading(false)
      setDetailList(data.list)
      setDetailCount(data.count)
    })
  }

  
  const onChangeTable2 = (pagination, filters, sorter) => {
    currentPage2 = pagination.current
    pageSize2 = pagination.pageSize
    api.creditAcountgetDetailed({
      accountId: dataVal.id,
      limit: pageSize2, 
      page: currentPage2,
      ...filter
    }).then(res =>{
      setLoading(false)
      setCreditAcount(res.list)
      setCreditAcountCount(res.count)
    })
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.appliedateMin = ''
      filter.appliedateMax = ''
    } else {
      filter.appliedateMin = moment(data[0]).format('YYYY-MM-DD')
      filter.appliedateMax = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const onChangePickers = (data) => {
    if (data.length === 0) {
      filter.startDate = ''
      filter.endDate = ''
    } else {
      filter.startDate = moment(data[0]).format('YYYY-MM-DD')
      filter.endDate = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  const onChangeTab = (key) => {
    setActiveKey(key)
    filter = {}
    setFieldsValue({
      project: undefined,
      number: undefined,
      vendor: undefined
    })
    if (key == 2) {
      setFieldsValue({
        coltype: undefined,
        appliedates: undefined
      })
      filter.startDate = ''
      filter.endDate = ''
      filter.project = ''
      filter.coltype = ''
      filter.number = ''
      filter.vendor = ''
      api.creditAcountgetDetailed({
        accountId: dataVal.id,
        limit: pageSize2, 
        page: currentPage2
      }).then(res =>{
        setCreditAcount(res.list)
        setCreditAcountCount(res.count)
        setColtypeList(res.coltypeList)
      })
    } else {
      setFieldsValue({
        appliedate: undefined
      })
      filter.appliedateMin = ''
      filter.appliedateMax = ''
      filter.project = ''
      filter.number = ''
      filter.vendor = ''
      api.getCreditarrearsList({
        ...filter, limit: pageSize, page: currentPage, id: editId, sproposerId: sproposerIdVal, type: type, oaqId: oaqId, creditType: accountType, companyId: companyId
      }).then(data => {
        setLoading(false)
        setDetailList(data.list)
        setDetailCount(data.count)
      })
    }
  }

  return (
    <>
      <div className="pro-detail">
        <Descriptions title="信用账户列表" className="pro-detail-smailtitle"></Descriptions>
        <Table
          dataSource={list}
          rowKey="id"
          pagination={false}
          loading={loading}
          style={{ marginBottom: 20 }}
        >
          <Column
            title="账户"
            dataIndex="company"
            key="company"
            render={(text, record) => <a onClick={() => onDetail(record)}>{record.company}</a>}
          />
          <Column title="账户类型" dataIndex="accountTypeName" />
          <Column title="关联项目" dataIndex="projectNames" />
          <Column title="总额度" dataIndex="totalAmount" />
          <Column title="已用额度" dataIndex="usedAmount" />
          <Column title="剩余可用额度" dataIndex="trueAmount" />
          <Column title="使用期限（天）" dataIndex="startDay" />
          <Column title="到期日" dataIndex="endDay" />
          <Column title="申请人" dataIndex="applicantName" />
          <Column title="负责人" dataIndex="proposerName" />
          <Column title="账户状态" dataIndex="isDel" />
          <Column title="账户ID" dataIndex="id" />
          <Column
            title="操作"
            key="set"
            render={(text, record) => {
              return (
                <>
                  {
                    record.isDel === '生效' ? <a onClick={() => onEdit(record)} style={{ marginRight: '10px'}}>调整</a> : null
                  }
                  <Link target='_blank' to={'/account/record?accountId=' + record.id}>履历</Link>
                </>
              )
            }}
          />
        </Table>
        <div className="line-box"></div>
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChangeTab}>
          {
            detailShow1 ? <TabPane tab="信用订单明细" key="1">
            {/* {detailShow1 ?
              <Descriptions title="信用订单明细" className="pro-detail-smailtitle"></Descriptions>
              : null} */}

            {searchShow ?
              <Form onSubmit={onSearch} {...formItemLayout}>
                <div className="search-item-wrap">
                  <Row gutter={24}>
                    <Col span={4}>
                      <FormItem label="项目">
                        {getFieldDecorator('project')(
                          <Select
                            placeholder="请选择"
                            showSearch={true}
                            optionFilterProp="children"
                            dropdownMatchSelectWidth={false}
                          >
                            {projectList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>    
                        )}
                      </FormItem>
                    </Col>

                    <Col span={5}>
                      <FormItem label="订单编号">
                        {getFieldDecorator('number', { initialValue: '' })(<Input placeholder="请输入" />)}
                      </FormItem>
                    </Col>

                    <Col span={5}>
                      <FormItem label="厂商ID">
                        {getFieldDecorator('vendor', { initialValue: '' })(<Input placeholder="请输入" />)}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem label="提单时间">
                        {getFieldDecorator('appliedate', { initialValue: '' })(<RangePicker onChange={onChangePicker} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <Button style={{ marginLeft: 8, marginTop: '4px' }} type="primary" htmlType="submit">查询</Button>
                    </Col>
                  </Row>
                </div>
              </Form>
              : null}

            {detailShow2 ?
              <Table
                dataSource={detailList}
                rowKey="id"
                pagination={{
                  pageSize,
                  total: detailCount,
                  current: currentPage,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                loading={loading}
                scroll={{ x: 1400 }}
                onChange={onChangeTable}
              >
                <Column title="订单编号" dataIndex="number" />
                <Column title="项目" dataIndex="projectName" />
                <Column title="产品" dataIndex="productName" />
                <Column title="厂商ID" dataIndex="vendor" />
                <Column title="提单人" dataIndex="userName" />
                <Column title="负责人" dataIndex="currentUserName" />
                <Column title="市场价" dataIndex="colmarketprice" />
                <Column title="预计收款" dataIndex="colpredictprice" />
                <Column title="扣款账户" dataIndex="creditAccountName" />
                <Column title="账户类型" dataIndex="creditType" />
                <Column title="订单状态" dataIndex="auditStatusName" />
                <Column title="提单时间" dataIndex="appliedate" />
                <Column 
                  title="操作"
                  key="set"
                  render={(text, record) => record.payback === true ? 
                    record.type == 0 ?
                    <a style={{marginRight: '10px'}} onClick={() => history.push(`/orderDirect/paybackApply?ids=${record.id}&vendor=${record.vendor}`)}>还款</a> :
                    <a style={{marginRight: '10px'}} onClick={() => history.push(`/orderAgent/paybackApply?ids=${record.id}&vendor=${record.vendor}`)}>还款</a> :
                    null
                  }/>
              </Table> : null}
          </TabPane> : null
          }
          {
            detailShow1 ? <TabPane tab="信用账户流水" key="2">
              <Form onSubmit={onSearch2} {...formItemLayout} >
                <div className="search-item-wrap">
                  <Row gutter={24}>
                    <Col span={6}>
                      <FormItem label="项目">
                        {getFieldDecorator('project')(
                          <Select
                            placeholder="请选择"
                            showSearch={true}
                            optionFilterProp="children"
                            dropdownMatchSelectWidth={false}
                          >
                            {projectList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>    
                        )}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem label="订单编号" >
                        {getFieldDecorator('number', { initialValue: '' })(<Input placeholder="请输入" />)}
                      </FormItem>
                    </Col>   
                    <Col span={6}>
                      <FormItem label="厂商ID">
                        {getFieldDecorator('vendor', { initialValue: '' })(<Input placeholder="请输入" />)}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem label="收款类型">
                        {getFieldDecorator('coltype', { initialValue: '' })(<Select
                            placeholder="请选择"
                            showSearch={true}
                            optionFilterProp="children"
                            dropdownMatchSelectWidth={false}
                          >
                            {coltypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                          </Select>)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={6}>
                      <FormItem label="提单时间">
                        {getFieldDecorator('appliedates', { initialValue: '' })(<RangePicker onChange={onChangePickers} />)}
                      </FormItem>
                    </Col>
                    <Col span={18}>
                      <Button style={{ marginLeft: 8, marginTop: '4px' }} type="primary" htmlType="submit">查询</Button>
                    </Col>
                  </Row>
                </div>
              </Form>
              <Table
                dataSource={creditAcount}
                rowKey="id"
                pagination={{
                  pageSize: pageSize2,
                  total: creditAcountCount,
                  current: currentPage2,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                loading={loading}
                scroll={{ x: 2800 }}
                onChange={onChangeTable2}
              >
                <Column title="订单编号" dataIndex="number" />
                <Column title="订单类型" dataIndex="businessType" />
                <Column title="客户名称" dataIndex="client" />
                <Column title="项目" dataIndex="projectName" />
                <Column title="产品" dataIndex="productName" />
                <Column title="厂商ID" dataIndex="vendor" />
                <Column title="提单人" dataIndex="userName" />
                <Column title="负责人" dataIndex="currentUserName" />
                <Column title="市场价" dataIndex="colmarketprice" />
                <Column title="预计收款" dataIndex="colpredictprice" />
                <Column title="信用额度变化" dataIndex="limitChange" />
                <Column title="信用额度变化时间" dataIndex="addTime" />
                <Column title="可用额度" dataIndex="trueAmount" />
                <Column title="已用额度" dataIndex="usedAmount" />
                <Column title="扣款账户" dataIndex="fundCompanyName" />
                <Column title="账户类型" dataIndex="accountType" />
                <Column title="收款类型" dataIndex="coltype" />
                <Column title="收款状态" dataIndex="colstatus" />
                <Column title="订单状态" dataIndex="auditStatusName" />
                <Column title="提单时间" dataIndex="appliedate" />
              </Table>
            </TabPane> : null
          }
        </Tabs>
      </div>
    </>
  )
}

export default Form.create()(CreditAccount)
