/**
 * 模块名称: 代理商调整积分驳回重提
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Descriptions,
  Steps,
  Table,
  Input,
  Icon,
  Form,
  message,
  Tag,
  Upload
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'

const dItem = Descriptions.item
const { Dragger } = Upload
const { Step } = Steps
const { Column } = Table
const { TextArea } = Input

const AgentIntegralEdit = (props) => {
  const { history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const search = parseSearch(props.location.search)
  const draggerRef = useRef(null)
  const [detailData, setDetailData] = useState({})
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [detailDataId, setdetailDataId] = useState('')
  const [buttonAuthList, setButtonAuthList] = useState({})
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [canEdit, setCanEdit] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [fileList, setFileList] = useState([])//附件

  useEffect(() => {
    api.getAgentIntegralInfo({ id: search.id }, true).then(data => {
      const attachmentsList = JSON.parse(data.attachments)
      if (attachmentsList.length !== 0) {
        if (attachmentsList.length) {
          attachmentsList.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(attachmentsList.map((item, index) => ({...item, uid: index})))
        }
      }
      data.bizTypeCn = data.bizType == 1 ? '退积分驳回重提' : '充积分驳回重提'
      data.integral = 0

      api.getStaffDetailInfo({}).then(tmps => {
        var staffName = tmps.staffName
        var staffId = tmps.id

        //获取积分账户信息
        api.getIntegralInfo({staffId: staffId, agentId: data.qId, companyId: data.companyId, projectId: data.projectId }).then(tmps => {
          data.integral = tmps.integral ? tmps.integral : 0
          setDetailData(data)
        })
      })

      setdetailDataId(data.id)
      setStatusList(data.procedureStatusList ? data.procedureStatusList : []) // 审批进度
      setLogConList(data.logList) // 审批日志
      setButtonAuthList(data.buttonAuthList) // 审批权限
    })
  }, [])
  
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  // 保存，申请
  const formSubmits = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
      if (!err) {
        setSaveLoading(true)
        var tmpdata = {
          id: detailData.id,
          num: vals.edit_num,
          remark: vals.edit_remark,
          attachments: fileList
        }

        api.saveAgentIntegral(tmpdata)
        .then(() => {
          setSaveLoading(false)
          message.success('操作成功')
          setTimeout(() => {
            history.push('/agent/integralManage')
          }, 1000);
        })
        .catch(() => setSaveLoading(false))
      }
    })
  }

  // 文件上传
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: ".rar, .zip, .doc, .docx, .pdf, .jpg, .png, .jpeg, .bmp",
		allowSizeType: 2
	})

  return (
    <>
      <div className="pro-detail pro-integralManage-detail">
        <Descriptions title={detailData.bizTypeCn} layout="horizontal " className="pro-detail-title">
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                  }
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>

        <Descriptions title="客户信息" layout="horizontal " className="pro-detail-title">
          <dItem label="">
            <>
            <b>{detailData.qName}（QID:{detailData.qId}）</b>
            </>
          </dItem>
        </Descriptions>
        {/* <div className="line-box"></div> */}

        <Descriptions title="" layout="horizontal " className="pro-detail-title">
          <dItem label="申请人">
            <>
              {detailData.applyStaffName}
            </>
          </dItem>
        </Descriptions>
        {/* <div className="line-box"></div> */}

        <Descriptions title="" layout="horizontal " className="pro-detail-title">
          {detailData.companyName && <dItem label="">
            <div className="mainNameBodySel">
              <img className="mainNameBodySelLogo" src={detailData.logo} alt="" />
              {detailData.companyName}
              <Icon className="check" type="check" />
            </div>
          </dItem>}
          <dItem label="项目">
            <>
              {detailData.projectName}
            </>
          </dItem>
          <dItem label="项目可用积分">
            <>
              {detailData.integral}
            </>
          </dItem>
        </Descriptions>
        {/* <div className="line-box"></div> */}
        
        <Descriptions title="" layout="horizontal " className="pro-detail-title">
        {detailData.bizType == 0 ? 
          <dItem label="充积分（数量）">
            <>
              {getFieldDecorator('edit_num', { initialValue: detailData.num })(<Input placeholder="请输入" />)}
            </>
          </dItem>
          :
          <dItem label="退积分（数量）">
            <>
            {getFieldDecorator('edit_num', { initialValue: detailData.num })(<Input placeholder="请输入" />)}
            </>
          </dItem>
        }

        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title="" layout="vertical">
          <Form.Item label="附件">
            <div className="dragger-box" ref={draggerRef}>
              <Dragger
                {...uploadFiles}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">
                  支持扩展名：.rar、zip、doc、docx、pdf、jpg, .png, .jpeg, .bmp 单个文件大小不超过5M，不超过5个附件。
                </p>
              </Dragger>
            </div>
          </Form.Item>
        </Descriptions>
        <div className="line-box"></div>
        <Descriptions title="" layout="horizontal " className="pro-detail-title">
          <dItem label="备注">
            <>
              {getFieldDecorator('edit_remark', { initialValue: detailData.remark })(
                <TextArea disabled={!canEdit} maxLength={100} rows={3} style={{width: '400px'}}></TextArea>
              )}
            </>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Table
          dataSource={logConList}
          rowKey="id"
          pagination={false}
        >
          <Column title="申请人/审批人" dataIndex="userInfo.staffName" />
          <Column title="审批时间" dataIndex="addTime" />
          <Column title="状态" dataIndex="status" />
          <Column title="备注" dataIndex="chargeMsg" />
        </Table>

        <Form onSubmit={formSubmits} {...formItemLayout}>
          <Descriptions title="" layout="horizontal" style={{paddingTop: '20px', marginBottom: 0}}>
            <Descriptions.Item label="当前状态">
              <Tag color="#2db7f5">{detailData.approvalStatusCn}</Tag>
            </Descriptions.Item>
          </Descriptions>

          <BtnGroup onCancel={() => history.push('/agent/integralManage')} loading={saveLoading} style={{float: 'right'}} />
        </Form>
      </div>
    </>
  )
}

export default Form.create()(AgentIntegralEdit)