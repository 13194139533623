/**
 * 模块名称: 资金账户列表
 * @author xuxiaobo@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  DatePicker,
  Descriptions
} from 'antd'
import { parseSearch } from '@/utils'
import moment from 'moment'

const { Column } = Table
const FormItem = Form.Item
const { RangePicker } = DatePicker
let editId = 0 //编辑使用
let pageSize = 10
let currentPage = 1
let relationType = 0
let customerId = 0

let filter = {}
const FundAccount = (props) => {
    const { location } = props
    const { getFieldDecorator, validateFields} = props.form
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [count, setCount] = useState(0)
    const [detailList, setDetailList] = useState([])
    const [detailCount, setDetailCount] = useState(0)
    const [authList, setAuthList] = useState([])
    const search = parseSearch(location.search)
    const [searchShow, setSearchShow] = useState(false)
    const [detailShow1, setDetailShow1] = useState(false)
    const [detailShow2, setDetailShow2] = useState(false)
    const [searchData, setSearchData]= useState({})
    const [onDetailVal, setOnDetailVal] = useState({}) // 暂存详情数据

    // currentPage = search.page ? +search.page : 1
    // pageSize = search.pageSize ? +search.pageSize : 10
    relationType = search.relationType == 1 ? 1 : 0
    customerId = search.customerId ? search.customerId : 0

    useEffect(() => {
      currentPage = 1
      pageSize = 10
      getList()
      api.getPageAuth().then(list => setAuthList(list))
    }, [])

    const getList = () => {
      setLoading(true)
      api.getFundAccountList({ ...filter, limit: global.paramsLimit, page: currentPage, type: 1, relationType: relationType, customerId: customerId })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
    }

    //详情
    const onDetail = (date) => {
      setSearchData({updateTime: undefined})
      // console.log(searchData)
      setOnDetailVal(date)
      setSearchShow(true)
      setDetailShow1(true)
      setDetailShow2(true)
      editId = date.id
      api.getFundDetailList({ ...filter, limit: pageSize, page: currentPage, id: date.id })
      .then(data => {
        setLoading(false)
        setDetailList(data.list)
        setDetailCount(data.count)
      })
    }

    // 查询
    const onSearch = (e) => {
      setSearchShow(true)
      setDetailShow1(true)
      setDetailShow2(true)
      e.preventDefault()
      validateFields((err, vals) => {
        // console.log(vals)
        if (!err) {
          filter = { ...filter, ...vals }
          currentPage = 1
          // history.replace(match.path)
          api.getFundDetailList({ ...filter, limit: pageSize, page: currentPage, id: editId, isFinished:1 })
          .then(data => {
            setLoading(false)
            setDetailList(data.list)
            setDetailCount(data.count)
          })
        }
      })
    }

    const onChangePicker = (data) => {
      if (data.length === 0) {
        filter.startFinishedTime = ''
        filter.endFinishedTime = ''
      } else {
        filter.startFinishedTime = moment(data[0]).format('YYYY-MM-DD')
        filter.endFinishedTime = moment(data[1]).format('YYYY-MM-DD')
      }
    }

    const onChangeTable = (pagination, filters, sorter) => {
      currentPage = pagination.current
      pageSize = pagination.pageSize
      // history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
      onDetail(onDetailVal)
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      }
    }

    return (
        <>
        <div className="pro-detail">
        <Descriptions title="资金账户列表" className="pro-detail-smailtitle"></Descriptions>

        <Table
          dataSource={list}
          rowKey="id"
          pagination={false}
          loading={loading}
          style={{marginBottom: 20}}
        >
          <Column title="账户编号" dataIndex="accountNo" />
          <Column title="账户" dataIndex="companyName" />
          <Column title="创建时间" dataIndex="addTime" />
          <Column title="账户余额" dataIndex="balance" />
          <Column title="负责人" dataIndex="stfName" />
          <Column title="操作"
          key="set" 
          render={(text, record) =>
            <>
              <a onClick={() => onDetail(record)}>详情</a>
            </>
          }
          />
        </Table>
        {detailShow1 ?<>
        <div className="line-box"></div>

        <Descriptions title="资金明细" className="pro-detail-smailtitle"></Descriptions> </>
        : null}

        {searchShow ? 
        <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
                <FormItem label="操作时间">
                  {getFieldDecorator('updateTime', { initialValue: searchData.updateTime})(<RangePicker onChange={onChangePicker} />)}
                </FormItem>
            <Button style={{ marginLeft: 8, marginTop: 4 }} type="primary" htmlType="submit">查询</Button>
        </div>
        </Form> 
         : null}                                

        {detailShow2 ?
        <Table
          dataSource={detailList}
          rowKey="id"
          pagination={{
            pageSize,
            total: detailCount,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          loading={loading}
          scroll={{x: 1400}}
          onChange={onChangeTable}
        >
          <Column title="业务编号" dataIndex="businessNo" />
          <Column title="业务类型" dataIndex="businessType" />
          <Column title="项目" dataIndex="projectName" />
          <Column title="产品" dataIndex="productName" />
          <Column title="类型" dataIndex="cashTypeName" />
          <Column title="金额" dataIndex="money" />
          <Column title="账户" dataIndex="companyName" />
          <Column title="操作人" dataIndex="operateStaffName" />
          <Column title="操作时间" dataIndex="finishedTime" />
        </Table>
        : null}
        {/* <div className="line-box"></div>   */}
        </div>
        </>
    )
}

export default Form.create()(FundAccount)