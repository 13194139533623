/**
 * 模块名称: 新增/编辑年度目标
 * @author yangzhihang@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Form,
	DatePicker,
	message,
	Input,
	Select,
	Row,
	Col,
} from 'antd'

import BtnGroup from '@/components/BtnGroup'
import moment from "moment";

const Option = Select.Option
const FormItem = Form.Item

const OperateTarget = (props) => {
	const { data, isNew, setVisible, dateFormat, getList } = props
	const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form

	const [saveLoading, setSaveLoading] = useState(false)
	const [stfList, setStfList] = useState([])
	const [isopen, setIsopen] = useState(false)
	const [persent, setPersent] = useState('')
	const [staff, setStaff] = useState({})
	const [editId, setEditId] = useState('')

	useEffect(() => {
		//获取员工信息
		api.getStfList().then(list => {
			setStfList(list)
		})
	}, [data, isNew])

	useEffect(() => {
		if(!isNew){
			const { id, staffId, year, assessItems, target, result, sort, assessSort } = data
			setFieldsValue({
				staffId,
				year: moment(year, dateFormat),
				assessItems,
				target,
				result,
				sort,
				assessSort,
			})
			setEditId(id)
			setStaff(stfList.find(item => item.id === staffId))
			const rate = dealNumber(result) / dealNumber(target) * 100
			if (!isNaN(rate) && isFinite(rate)) {
				setPersent(rate.toFixed(2) + '%')
			}
		}else{
			setFieldsValue({
				staffId: undefined,
				year: undefined,
				assessItems: '' ,
				target: '' ,
				result: '' ,
				sort: '' ,
				assessSort: ''
			})
		}
	}, [stfList])

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		}
	}

	// 提交添加
	const formSubmit = evt => {
		evt.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				setSaveLoading(true)
				vals.year = vals.year.format(dateFormat)
				if (!isNew) {
					api.saveAchYear({...vals, id: editId}).then(
						() => {
							setSaveLoading(false)
							setVisible(false)
							message.success('修改成功')
							getList()
						}
					).catch(() => setSaveLoading(false))
				}else{
					api.saveAchYear({...vals}).then(
						() => {
							setSaveLoading(false)
							setVisible(false)
							message.success('添加成功')
							getList()
						}
					).catch(() => setSaveLoading(false))
				}
			}
		})
	}

	const dealNumber = num => {
		let res
		if (num.toString().indexOf('%') > -1) {
			res = parseFloat(num) / 100
		} else {
			res = parseFloat(num)
		}
		return res
	}

	const onBlurTarget = evt => {
		const target = dealNumber(evt.target.value)
		const result = dealNumber(getFieldValue('result'))
		const rate = result / target * 100
		if (!isNaN(rate) && isFinite(rate)) {
			setPersent(rate.toFixed(2) + '%')
		}
	}

	const onBlurResult = evt => {
		const result = dealNumber(evt.target.value)
		const target = dealNumber(getFieldValue('target'))
		const rate = result / target * 100
		if (!isNaN(rate) && isFinite(rate)) {
			setPersent(rate.toFixed(2) + '%')
		}
	}

	// 弹出日历和关闭日历的回调
	const handleOpenChange = status => setIsopen(status)
	// 拿到value并进行处理
	const handlePanelChange = value => {
		// 拿到年份
		setIsopen(false)
		setFieldsValue({ year: value })
	}

	const staffChange = value => {
		const data = stfList.find(item => item.id === value)
		setStaff(data)
		if (data.sort !== 0) {
			setFieldsValue({ sort: data.sort })
		} else {
			setFieldsValue({ sort: '' })
		}
	}

	return (
		<Form onSubmit={formSubmit} {...formItemLayout}>
			<FormItem label="姓名">
				{getFieldDecorator('staffId', {
					rules: [{ required: true, message: '请选择姓名' }]
				})(
					<Select 
						placeholder="请选择" 
						style={{ width: 177 }} 
						disabled={!isNew} 
						onChange={staffChange}
						showSearch
						filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						{stfList.map(item => <Option key={item.id} value={item.id} >{item.staffName}</Option>)}
					</Select>
				)}
			</FormItem>
			<FormItem label="目标年份">
				{getFieldDecorator('year', {
					rules: [{ required: true, message: '请输入年份' }]
				})(<DatePicker 
					open={isopen} 
					mode="year"
					placeholder="请选择年份"
					format="YYYY" 
					onOpenChange={handleOpenChange} 
					onPanelChange={handlePanelChange}
					disabled={!isNew}
				/>)}
			</FormItem>
			<FormItem label="考核项">
				{getFieldDecorator('assessItems', {
					rules: [{ required: true, message: '请输入考核项' }]
				})(<Input 
					placeholder="请输入" 
					maxLength={18}
					disabled={!isNew}
				/>)}
			</FormItem>
			<FormItem label="年度目标">
				{getFieldDecorator('target', {
					rules: [
						{ required: true, message: '请输入年度目标' },
						{ pattern: /(^[1-9]{1}[0-9]{0,11}(\.[0-9]{1,2})?%?$)|(^0%?$)|(^[0-9]\.[0-9]([0-9])?%?$)/, message: '仅可填写0和正数，最多填写小数点后两位，最多填写12位整数' }
					]
				})(<Input 
					onBlur={onBlurTarget} 
					placeholder="请输入" 
					maxLength={15}
				/>)}
			</FormItem>
			<FormItem label="完成结果">
				{getFieldDecorator('result', {
					rules: [
						{ required: true, message: '请输入完成结果' },
						{ pattern: /(^-?[1-9]{1}[0-9]{0,11}(\.[0-9]{1,2})?%?$)|(^0%?$)|(^-?[0-9]\.[0-9]([0-9])?%?$)/, message: '仅可填写数字，最多填写小数点后两位，最多填写12位整数' }
					]
				})(<Input 
					onBlur={onBlurResult} 
					placeholder="请输入" 
					maxLength={16}
				/>)}
			</FormItem>
			<FormItem label="完成率">
				<Input value={persent} disabled />
			</FormItem>
			<FormItem label="列表排序" extra="小程序绩效列表的排序，数值越大排名越靠前">
				{getFieldDecorator('sort', {
					rules: [
						{ required: true, message: '请输入列表排序' }, 
						{ pattern: /^([1-9]\d*|[0]{1,1})$/, message: '仅能输入0和正整数' }
					]
				})(<Input 
					placeholder="请输入" 
					maxLength={4}
				/>)}
			</FormItem>
			<FormItem label="考核项排序" extra="绩效详情中考核项的排序，数值越大排名越靠前">
				{getFieldDecorator('assessSort', {
					rules: [{ required: false, message: '请输入列表排序' }, {
						pattern: /^([1-9]\d*|[0]{1,1})$/, message: '仅能输入0和正整数'
					}]
				})(<Input 
					placeholder="请输入" 
					maxLength={4}
				/>)}
			</FormItem>
			<FormItem label="人员信息">
				<Row gutter={[16, 20]}>
					<Col span={8}>
						<div>姓名：{staff && staff.staffName}</div>
					</Col>
					<Col span={16}>
						<div>员工号：{staff && staff.staffNo}</div>
					</Col>
					<Col span={8}>
						<div>职位：{staff && staff.jobPosition}</div>
					</Col>
					<Col span={16}>
						<div>所在事业群：{staff && staff.groupName}</div>
					</Col>
				</Row>
			</FormItem>
			<BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
		</Form>
	)
}

export default Form.create()(OperateTarget)