/**
 * 模块名称: 账户管理
 * 资金账户列表
 * 信用账户列表
 * 信用额度审批
 * 信用账户统计（可延后）
 * 申请信用额度
 * 调整信用账户
 * 信用账户列表=》履历
 * @author xuxiaobo@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import FundAccount from './FundAccount'
import CreditAccount from './CreditAccount'
import Main from './Main'
import Details from './Details'
import Add from './Add'
import Edit from './Edit'
import Record from './Record'
import './assets/style.scss'

const Account = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/credit'} component={CreditAccount} />
      <Route path={match.path + '/creditapply/details'} component={Details} />
      <Route path={match.path + '/creditapply/add'} component={Add} />
      <Route path={match.path + '/creditapply/edit'} component={Edit} />
      <Route path={match.path + '/creditapply'} component={Main} />
      <Route path={match.path + '/record'} component={Record} />
      <Route component={FundAccount} />
    </Switch>
  )
}

export default Account