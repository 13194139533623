/**
 * 模块名称: 代理商调整积分详情页
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Steps,
  Table,
  Button,
  Modal,
  Input,
  Icon,
  Tag,
  message
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table

const AgentIntegralDetail = (props) => {
  const { history } = props
  const search = parseSearch(props.location.search)
  const [detailData, setDetailData] = useState({})
  const [statusList, setStatusList] = useState([])
  const [logConList, setLogConList] = useState([])
  const [detailDataId, setdetailDataId] = useState('')
  const [buttonAuthList, setButtonAuthList] = useState({})
  const [passModalVisible, setModalVisible] = useState(false) //确认弹框
  const [turnModalVisible, setTurnModalVisible] = useState(false) //驳回弹框
  const [textDisplay, setTextDisplay] = useState(true) //是否提示必选
  const [textAreaVal, setTextAreaVal] = useState('') //判断框是否为空
  const [pasDesc, setPasDesc] = useState('')  //通过意见
  const [showFile, setShowFile] = useState([])//附件

  useEffect(() => {
    // var data = {}
    // data.bizType = search.bizType
    // setDetailData(data)
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getAgentIntegralInfo({ id: search.id }, true).then(data => {
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        if (data.attachments) {
          const arr = JSON.parse(data.attachments)
          setShowFile(arr)
        }
        setDetailData(data)
        setdetailDataId(data.id)
        setStatusList(data.procedureStatusList ? data.procedureStatusList : []) // 审批进度
        setLogConList(data.logList) // 审批日志
        setButtonAuthList(data.buttonAuthList) // 审批权限
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  //审批类型 0通过 1驳回 2转上级 3补充资料
  // 转上级
  const onTurnUp = () => {
    api.auditAgentIntegral({
      id: detailDataId,
      type: 2,
      remark: ''
    }).then(data => {
      history.push('/agent/integralManage')
    }).catch()
  }

  // 补充资料
  const onReplenish = () => {
    api.auditAgentIntegral({
      id: detailDataId,
      type: 3,
      remark: ''
    }).then(data => {
      history.push('/agent/integralManage')
    }).catch()
  }

  // 驳回
  const onBtnTurnShow = () => {
    setTurnModalVisible(true)
    setTextDisplay(true)
  }

  const onTurnCancel = () => {
    setTurnModalVisible(false)
  }

  // 驳回样式
  const turnNode = () => {
    return <>
      确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
    </>
  }

  // 同意样式
  const agreeNode = () => {
    return <>
      确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </>
  }

  const onTurnApprove = () => {
    if (buttonAuthList.reject_msg_require) {
      if (textAreaVal.trim() === '') {
        setTextDisplay(false)
        return
      }
    }

    //审批类型 0通过 1驳回 2转上级 3补充资料
    api.auditAgentIntegral({
      id: detailDataId,
      type: 1,
      remark: textAreaVal
    }).then(data => {
      api.getAgentIntegralInfo({ id: search.id }, true).then(data => {
        setDetailData(data)
        if (data.attachments) {
          const arr = JSON.parse(data.attachments)
          setShowFile(arr)
        }
        setdetailDataId(data.id)
        setStatusList(data.procedureStatusList ? data.procedureStatusList : []) // 审批进度
        setLogConList(data.logList) // 审批日志
        setButtonAuthList(data.buttonAuthList) // 审批权限
      }) // 确认驳回
    })
    setTurnModalVisible(false)
  }  // 通过
  const onBtnPassShow = () => {
    setModalVisible(true)
  }

  const onPassCancel = () => {
    setModalVisible(false)
  }

  const onPassDescChange = (e) => {
    setPasDesc(e.target.value)
  }

  const onApprove = () => {
    //审批类型 0通过 1驳回 2转上级 3补充资料
    api.auditAgentIntegral({
      id: detailDataId,
      type: 0,
      remark: pasDesc
    }).then(data => {
      api.getAgentIntegralInfo({ id: search.id }, true).then(data => {
        setDetailData(data)
        if (data.attachments) {
          const arr = JSON.parse(data.attachments)
          setShowFile(arr)
        }
        setdetailDataId(data.id)
        setStatusList(data.procedureStatusList ? data.procedureStatusList : []) // 审批进度
        setLogConList(data.logList) // 审批日志
        setButtonAuthList(data.buttonAuthList) // 审批权限
      }) // 确认通过后
    })
    setModalVisible(false)
  }

  const onDescChange = (e) => {
    setTextAreaVal(e.target.value)
    setTextDisplay(true)
  }

  return (
    <>
      <div className="pro-detail pro-integralManage-detail">
        <Descriptions title={detailData.bizType == 0 ? "充积分详情" : "退积分详情"} layout="horizontal " className="pro-detail-title">
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
        <div className="promotionProcess" >
          <Steps current={detailData.current}>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                  }
                />
              })
            }
          </Steps>
        </div>
        <div className="line-box"></div>

        <Descriptions title="客户信息" layout="horizontal " className="pro-detail-title">
          <dItem label="">
            <>
            <b>{detailData.qName}（QID:{detailData.qId}）</b>
            </>
          </dItem>
        </Descriptions>
        {/* <div className="line-box"></div> */}

        <Descriptions title="" layout="horizontal " className="pro-detail-title">
          <dItem label="申请人">
            <>
              {detailData.applyStaffName}
            </>
          </dItem>
        </Descriptions>
        {/* <div className="line-box"></div> */}

        <Descriptions title="" layout="horizontal " className="pro-detail-title">
          {detailData.companyName && <dItem label="">
            <div className="mainNameBodySel">
              <img className="mainNameBodySelLogo" src={detailData.logo} alt="" />
              {detailData.companyName}
              <Icon className="check" type="check" />
            </div>
          </dItem>}
          <dItem label="项目">
            <>
              {detailData.projectName}
            </>
          </dItem>
          <dItem label="项目可用积分">
            <>
              {detailData.remainNum}
            </>
          </dItem>
        </Descriptions>
        {/* <div className="line-box"></div> */}

        <Descriptions title="" layout="horizontal " className="pro-detail-title">
        {detailData.bizType == 0 ? 
          <dItem label="充积分（数量）">
            <>
              {detailData.num}
            </>
          </dItem>
          :
          <dItem label="退积分（数量）">
            <>
              {detailData.num}
            </>
          </dItem>
        }

        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title="" layout="horizontal " className="pro-detail-title">
          <dItem label="附件">
            {
              showFile && showFile.length > 0 ? showFile.map((item, i) => {
                return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
              }) : ''
            }
          </dItem>
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title="" layout="horizontal " className="pro-detail-title">
          <dItem label="备注">
            <>
              {detailData.remark}
            </>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions
          title="审批记录"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
        </Descriptions>
        <Table
          dataSource={logConList}
          rowKey="id"
          pagination={false}
        >
          <Column title="申请人/审批人" dataIndex="userInfo.staffName" />
          <Column title="审批时间" dataIndex="addTime" />
          <Column title="状态" dataIndex="status" />
          <Column title="备注" dataIndex="chargeMsg" />
        </Table>

        <Descriptions title="" layout="horizontal" style={{paddingTop: '20px'}}>
          <Descriptions.Item label="当前状态">
            <Tag color="#2db7f5">{detailData.approvalStatusCn}</Tag>
          </Descriptions.Item>
        </Descriptions>

        <div className="btn-setting">
          <Button hidden={!buttonAuthList.replenish} onClick={onReplenish}>补充资料</Button>
          <Button hidden={!buttonAuthList.return_up} onClick={onTurnUp}>转上级</Button>
          <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
          <Modal
            title={turnNode()}
            // title="确认驳回"
            visible={turnModalVisible}
            onOk={onTurnApprove}
            onCancel={onTurnCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
            </div>
            <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
          </Modal>
          <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
          <Modal
            // title="确认通过"
            title={agreeNode()}
            visible={passModalVisible}
            onOk={onApprove}
            onCancel={onPassCancel}
          >
            <div>
              <span>审批意见:</span>
              <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
            </div>
          </Modal>
        </div>
        
      </div>
    </>
  )
}

export default AgentIntegralDetail;