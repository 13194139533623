/**
 * 模块名称: 年度目标管理
 * @author yangzihang@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  Table,
  Form,
  Drawer,
  DatePicker,
  Input,
  Button,
  Alert,
  Popconfirm,
  message,
} from 'antd'
import Auth from 'components/AuthMiddleware'
import { parseSearch } from 'utils'
import OperateTarget from "./module/OperateTarget"

const { Column } = Table
const FormItem = Form.Item
const dateFormat = 'YYYY'

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage
}

let intervalRef = {}

const YearTarget = props => {

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const { history, match, location } = props
  const search = parseSearch(location.search)

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [isopen, setIsopen] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [editData, setEditData] = useState({})
  const [isNew, setIsNew] = useState(false)
  const [authList, setAuthList] = useState([])//按钮权限

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getList()
    getPageAuth()
    return () => {
      for(let i in intervalRef){//eslint-disable-line
        clearTimeout(intervalRef[i])
      }
    }
  }, [])
  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取列表
  const getList = () => {
    intervalRef.current = setTimeout(() => {
      api.getYearList(searchParamsTmp).then(res => {
        setList(res.list)
        setCount(res.count)
        setLoading(false)
        clearTimeout(intervalRef.current)
      }).catch(() => { setLoading(false) })
    }, 500)
  }

  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize, 
      page: currentPage
    }
    getList()
  }
  //提交
  const formSubmit = evt => {
    evt.preventDefault()
    validateFields((err, vals) => {
      if(vals !== undefined){
        if(vals.staffName !== undefined)searchParamsTmp.staffName = vals.staffName
        if(vals.year !== undefined) searchParamsTmp.year = vals.year.format(dateFormat)
      }
      if(!err){
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getList()
      }
    })
  }
  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getList()
  }

  // 弹出日历和关闭日历的回调
  const handleOpenChange = status => setIsopen(status)
  // 拿到value并进行处理
  const handlePanelChange = value => {
      // 拿到年份
      setIsopen(false)
      setFieldsValue({ year: value })
  }

  // 添加
  const onAdd = () => {
    setEditData({})
    setIsNew(true)
    setEditVisible(true)
  }

  const onEdit = data => {
    setEditData(data)
    setIsNew(false)
    setEditVisible(true)
  }

  const onDelete = id => {
    api.delYear({
      id: id
    }).then(() => {
      message.success('删除成功')
      getList()
    })
  }

  return (
   <>
    <div className="search-doc-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <div className="form-box">
            <FormItem label="姓名">
              {getFieldDecorator('staffName', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="目标年度">
              {getFieldDecorator('year', {
              })(<DatePicker 
                  open={isopen} 
                  mode="year"
                  placeholder="请选择年份"
                  format={dateFormat} 
                  onOpenChange={handleOpenChange} 
                  onPanelChange={handlePanelChange}
                />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div>
        <div className="add-wrap">
          <Auth auths={authList} code="add">
            <Button type="primary" icon="plus" onClick={onAdd}>新增</Button>
          </Auth>
        </div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="small"
          dataSource={list}
          rowKey="id"  
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="目标年度" dataIndex="year" />
          <Column title="姓名" dataIndex="staffName" />
          <Column title="职位" dataIndex="jobPosition" />     
          <Column title="直接主管" dataIndex="leaderName" />
          <Column title="所在事业群" dataIndex="groupName" />
          <Column title="所在部门" dataIndex="departmentName" />
          <Column title="员工号" dataIndex="staffNo" />
          <Column title="列表排序" dataIndex="sort" />
          <Column title="考核项排序" dataIndex="assessSort" />
          <Column title="考核项" dataIndex="assessItems" />
          <Column title="年度目标" dataIndex="target" />
          <Column title="完成结果" dataIndex="result" />
          <Column title="完成率" dataIndex="completionRate" />
          <Column title="操作"
            key="set" 
            fixed="right"
            align="center"
            width={100}
            render={(text, record) =>
              <>
                <Auth auths={authList} code="edit">
                  <a onClick={() => onEdit(record)}>编辑</a>
                </Auth>
                {' '}
                <Auth auths={authList} code="delete">
                  <Popconfirm title="请确认是否删除？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                      <a>删除</a>
                  </Popconfirm>
                </Auth>
              </>
            }
          />
        </Table>
      </div>
      <Drawer
        title={isNew ? '新增年度目标' : '编辑年度目标'}
        width={600}
        onClose={() => setEditVisible(false)}
        visible={editVisible}
        destroyOnClose={true}
      >
        <OperateTarget data={editData} setVisible={setEditVisible} dateFormat={dateFormat} getList={getList} isNew={isNew} />
      </Drawer>
   </> 
  )
}

export default Form.create()(YearTarget)
