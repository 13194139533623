/**
 * 模块名称: 信用账户列表=》履历
 * @author 刘晶雪@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import { Link } from 'react-router-dom'
import {
	Table,
	Form
} from 'antd'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

const { Column } = Table
let pageSize = 10
let currentPage = 1

const Record = (props) => {
	const { location } = props
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [count, setCount] = useState(0)
	const search = parseSearch(location.search)


	useEffect(() => {
		getList()
	}, [])

	const getList = () => {
		setLoading(true)
		api.creditAcountGetResumeList({
			accountId: search.accountId,
			limit: pageSize, 
			page: currentPage
		}).then(data => {
			setLoading(false)
			setList(data.list)
			setCount(data.count)
		}).catch(() => setLoading(false))
	}

	const onChangeTable = (pagination, filters, sorter) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		getList()
	}

	return (
		<>
			<Table
				dataSource={list}
				rowKey="id"
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				loading={loading}
				onChange={onChangeTable}
			>
				<Column title="操作类型" dataIndex="operationType" />
				<Column title="生效时间" dataIndex="addTime" />
				<Column
					title="业务编号"
					dataIndex="businessNo"
					key="businessNo"
					render={(text, record) => <>{
						record.operationType == '额度调整' || record.operationType == '账户生成' ? 
						<Link to={`/account/creditapply/details?id=${record.creditId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.creditId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link> :
						record.operationType == '账户转移' ? 
						record.ctype == 0 ? <Link to={`/csr/turnAudit/detail?id=${record.creditId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.creditId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link> :
						<Link to={`/agent/agentTransfer/detail?id=${record.creditId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.creditId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link> : 
						null
					}</>}
				/>
				<Column title="项目增加" dataIndex="addProjects" />
				<Column title="项目减少" dataIndex="subProjects" />
				<Column title="额度增加" dataIndex="addAmount" />
				<Column title="额度减少" dataIndex="subAmount" />
				{/* <Column title="剩余可用额度" dataIndex="trueAmount" /> */}
				<Column title="到期日变更" dataIndex="startDay" />
				<Column title="申请人" dataIndex="applicantName" />
				<Column title="负责人" dataIndex="proposerName" />
				{/* <Column title="账户状态" dataIndex="isDel" /> */}
			</Table>
		</>
	)
}

export default Form.create()(Record)