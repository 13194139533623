/**
 * 模块名称: 编辑绩效结果
 * @author yangzhihang@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Form,
	message,
	Input,
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const FormItem = Form.Item

const EditResult = (props) => {
	const { data, setVisible, getList } = props
	const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form

	const [saveLoading, setSaveLoading] = useState(false)
	const [targList, setTargList] = useState([])

	useEffect(() => {
		const { staffName, month, targList } = data
		setTargList(targList)
		setFieldsValue({
			staffName,
			month,
		})
	}, [data])

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		}
	}

	const isRepeat = arr => {
		const hash = {}
		for(let i in arr) {
			const key = arr[i].trim()
			if(hash[key]) {
				return true
			}
			hash[key] = true
		}
		return false
	}

	// 提交添加
	const formSubmit = evt => {
		evt.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				setSaveLoading(true)
				const { datas, assessItems, target, result, weight, isAssess } = vals
				const sum = weight.reduce((prev, cur) => {
					if (cur === '') {
						cur = 0
					} else {
						cur = parseFloat(cur)
					}
					return prev + cur
				}, 0)

				if (sum !== 100) {
					setSaveLoading(false)
					return message.warning('所有考核项的考核权重相加必须等于100%，请检查')
				}
				if (isRepeat(assessItems)) {
					setSaveLoading(false)
					return message.warning('当前绩效月份已有该考核项，请检查')
				}

				datas.forEach((item, index) => {
					item.assessItems = assessItems[index]
					item.target = target[index]
					item.result = result[index]
					item.weight = weight[index]
					item.isAssess = isAssess[index]
					delete item.completionRate
				})

				api.updateAchResult(datas).then(
					() => {
						setSaveLoading(false)
						setVisible(false)
						message.success('修改成功')
						getList()
					}
				).catch(() => setSaveLoading(false))
			}
		})
	}

	getFieldDecorator('datas', { initialValue: targList })
	const datas = getFieldValue('datas')

	const formItems = datas.map((item, index) => {
		const { assessItems, target, result, weight, isAssess } = item

		return (
			<div key={index}>
				<FormItem label="考核项">
					{getFieldDecorator(`assessItems[${index}]`, {
						rules: [{ required: true, message: '请输入考核项' }],
						initialValue: assessItems
					})(<Input 
						placeholder="请输入" 
						maxLength={18}
					/>)}
				</FormItem>
				<div style={{ display: item.isAssess === '是' ? 'block' : 'none' }}>
					<FormItem label="考核权重">
						{getFieldDecorator(`weight[${index}]`, {
							rules: [
								{ required: item.isAssess === '是', message: '请输入考核权重' },
								{ pattern: /(^[1-9]?[0-9](\.[0-9]{1,2})?%$)|(^0$)|(^100%$)/, message: '仅可填写0到100%' }
							],
							initialValue: weight
						})(<Input 
							placeholder="请输入" 
						/>)}
					</FormItem>
				</div>
				<FormItem label="考核目标">
					{getFieldDecorator(`target[${index}]`, {
						rules: [
							{ required: true, message: '请输入年度目标' },
							{ pattern: /(^[1-9]{1}[0-9]{0,11}(\.[0-9]{1,2})?%?$)|(^0%?$)|(^[0-9]\.[0-9]([0-9])?%?$)/, message: '仅可填写0和正数，最多填写小数点后两位，最多填写12位整数' }
						],
						initialValue: target
					})(<Input 
						placeholder="请输入" 
						maxLength={15}
					/>)}
				</FormItem>
				<FormItem label="完成结果">
					{getFieldDecorator(`result[${index}]`, {
						rules: [
							{ required: true, message: '请输入完成结果' },
							{ pattern: /(^-?[1-9]{1}[0-9]{0,11}(\.[0-9]{1,2})?%?$)|(^0%?$)|(^-?[0-9]\.[0-9]([0-9])?%?$)/, message: '仅可填写数字，最多填写小数点后两位，最多填写12位整数' }
						],
						initialValue: result
					})(<Input 
						placeholder="请输入" 
						maxLength={16}
					/>)}
				</FormItem>
				<FormItem label="绩效考核">
					{getFieldDecorator(`isAssess[${index}]`, {
						initialValue: isAssess
					})(<Input disabled />)}
				</FormItem>
			</div>
			
		)
	})

	return (
		<Form onSubmit={formSubmit} {...formItemLayout}>
			<FormItem label="姓名">
				{getFieldDecorator('staffName')(<Input disabled />)}
			</FormItem>
			<FormItem label="绩效月份">
				{getFieldDecorator('month')(<Input disabled />)}
			</FormItem>
			{formItems}
			<BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
		</Form>
	)
}

export default Form.create()(EditResult)