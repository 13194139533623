/**
 * 模块名称: 导入结果数据
 * @author yangzhihang@372163.com
 */

import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom'
import { Form, Icon, message, Upload, Modal, Select } from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { upLoadModule } from '@/utils/common'

const Option = Select.Option
const { Dragger } = Upload
let disabled = true

const ImportResult = props => {
  // console.log('props', props)
  const { importFn, visibleFile, onCloseImport, ImportTemplate, ImportRef, title, monthList } = props
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [month, setMonth] = useState('')

  const draggerRef = useRef(null)

  useEffect(() => {
    disabled = true
  }, [])

  useEffect(() => {
    setMonth(monthList[0])
  }, [monthList])

  //
  useImperativeHandle(ImportRef, () => ({
    // onRemoved 就是暴露给父组件的方法
    onRemoved: () => {
      for (let i = 0; i < fileList.length; i++) {
        fileList.splice(i, 1)
        i--
      }
      setFileList(fileList)
      let timer = setTimeout(() => {
        //隐藏上传错误的file
        let wrongLoad = ReactDOM.findDOMNode(draggerRef.current).querySelectorAll('.ant-upload-list-item-done')
        wrongLoad.forEach((item, index, self) => {
          self[index].style.display = 'none'
        })
        clearTimeout(timer)
      }, 500)
    },
    onLoading: () => {
      setLoading(false)
    }
  }))

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.xls, .xlsx',
    allowSizeType: 3,
    import: 1
	})

  const onConfirm = () => {
    if (!fileList.length) {
      message.error('请导入模板')
      return
    }
    setLoading(true)
    if (disabled) {
      importFn(fileList, month)
      disabled = false
      let timer = setTimeout(() => {
        disabled = true
        clearTimeout(timer)
      }, 1000)
    } else {
      message.error('请不要频繁点击')
      return
    }

  }

  const onClose = () => {
    if (fileList.length > 0) {
      api.fileDelFile({ fileUrl: fileList[0].full })
    }
    onCloseImport()
  }

  // 下载模版
  const downloadTemplate = () => {
    api.getHost().then(res => {
      window.location = `${res.url}${ImportTemplate}?month=${month}`
    })
  }

  return (
    <Modal
      title={title || '导入数据'}
      width={800}
      onCancel={onClose}
      visible={visibleFile}
      destroyOnClose={true}
      footer={null}
    >
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <div style={{ marginBottom: '20px' }}>
          绩效导入月份：<Select 
						placeholder="请选择" 
						style={{ width: 177 }} 
            value={month}
						onChange={value => setMonth(value)}
            showSearch
					>
						{monthList.reverse().map((item, index) => <Option key={index} value={item} >{item}</Option>)}
					</Select>
        </div>
        <a onClick={downloadTemplate}>【点击此链接下载导入模板】</a>
      </div>
      <div className="dragger-box" ref={draggerRef}>
        <Dragger
          {...uploadFiles}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">点击或将文件拖拽到这里导入</p>
          <p className="ant-upload-hint">
            支持扩展名：.xls, .xlsx
                    </p>
          <p className="ant-upload-hint">
            文件大小不超过10M，只能上传1个附件。
                    </p>
        </Dragger>
        <BtnGroup style={{ marginTop: 100 }} cancelName="取消" confirmName="导入" onConfirm={onConfirm} onCancel={onClose} loading={loading}/>
      </div>
    </Modal>
  )
}

export default Form.create()(ImportResult)