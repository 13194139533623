/**
 * 模块名称: 申请信用额度，调整界面
 * @author xuxiaobo@372163.com
 */

import React, {useEffect, useRef, useState} from 'react'
import {Col, Descriptions, Form, Icon, Input, message, Row, Select, Upload, Modal} from 'antd'
import api from '@/api'
import {parseSearch} from '@/utils'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'
import moment from 'moment'

const Option = Select.Option
const {Dragger} = Upload;
const {TextArea} = Input
const { confirm } = Modal
let describeUrl = []
let editId = 0 //编辑使用
let creditAccountData = {}
let accountType = '0'

const CreditApplyEdit = (props) => {
    const {location, history} = props
    const {getFieldDecorator, validateFields, resetFields, setFieldsValue} = props.form
    const search = parseSearch(location.search)

    const [visible, setVisible] = useState(false)
    const [visibledraggerRef, ] = useState(false)
    const [visibleEdit3, setVisibleEdit3] = useState(false)
    const draggerRef = useRef(null)
    const [fileList, setFileList] = useState([])
    const [projects, setProjects] = useState([])
    const [saveLoading, setSaveLoading] = useState(false)
    const [fundAccountList, setFundAccountList] = useState([])
    const [canEdit, ] = useState(true)
    const [oldData, setOldData] = useState({})
    const [accountId, setAccountId] = useState('')
    const [addAfterAmount, setAddAfterAmount] = useState({})
    const [addAfterAmountName, setAddAfterAmountName] = useState('')
    const [affectTime, setAffectTime] = useState('')
    const [loseEfficacy, setLoseEfficacy] = useState('')
    const [color, setColor] = useState(false)

    useEffect(() => {
        // console.log(search)
        if (search.accountId) getCreditAccountCheckAccount(search.accountId)
        //0发起申请,1发起调整
        var action = search.action ? search.action : 'apply'

        //0直销,1代理商
        var customerType = search.customerType ? search.customerType : '0'
        var customerId = search.customerId ? search.customerId : '0'

        //长期传0， 临时传1
        accountType = search.accountType ? search.accountType : '0'

        //处理记录主键
        var id = search.id ? search.id : '0'

        //账户主键
        var accountId = search.accountId ? search.accountId : '0'

        //帐户编号，公司编号
        var companyId = search.companyId ? search.companyId : '0'

        //0发起申请,1发起调整
        onEdit1({
            customerId: customerId,
            customerType: customerType,
            accountType: accountType,
            accountId: accountId,
            companyId: companyId,
            id: id
        })
    }, [])

     //统判断该客户是否有申请人选中的资金账户下的信用账户（长期或临时）
    const getCreditAccountCheckAccount = (accountId, startDay) => {
        api.getCreditAccountCheckAccount({
            oaQId: search.customerId,
            type: search.customerType,
            accountType: search.accountType,
            companyId: search.companyId,
            accountId: accountId
        }).then(res => {
            // console.log(res.accountId)
            if (res.accountId) {
                setAccountId(res.accountId)
                setAffectTime(res.affectTime)
                if (search.id) {
                    setLoseEfficacy(calc(res.affectTime, startDay, 1))
                    //判断失效日期是否小于当前日期
                    if (search.accountType == 1) {
                        let expiryDate = calc(res.affectTime, startDay)//失效日期
                        if (expiryDate < moment(new Date()).format('YYYY-MM-DD')) {
                            setColor(true)
                        } else {
                            setColor(false)
                        }
                    }
                }
            } else {
                setVisible(true)
            }
        }).catch(err => console.log(err))
    }

    const handleOk = () => {
        history.push(`/account/creditapply/add?customerType=${search.customerType}&customerId=${search.customerId}&accountType=${search.accountType}`)
    }

    const handleCancel = () => {
        history.goBack()
        setVisible(false)
    }

    //发起调整
    const onEdit1 = async (date) => {
        setVisibleEdit3(true)
        resetFields()

        var customerType = date.customerType
        var customerId = date.customerId
        var id = date.id
        var accountId = date.accountId

        if (id > 0) {
            //数据类型 0展示详情 1驳回重提详情
            await api.getCreditApplyDetail({id: id, type: 1}, true).then(data => {
                try {
                    describeUrl = JSON.parse(data.describeUrl)
                } catch (e) {
                }
                try {
                    describeUrl = JSON.parse(JSON.parse(data.describeUrl))
                } catch (e) {
                }
                setAddAfterAmountName(data.addAmount)
                api.getCreditAccountGetAddAfterAmount({ accountId: data.accountId, addAmount: data.addAmount }).then(res => {
                    // console.log(res)
                    setAddAfterAmount(res)
                }).catch(err => console.log(err))
                setOldData(data.oldProject)
                editId = data.id
                getCreditAccountCheckAccount(data.accountId, data.startDay)
                // console.log(data)

                creditAccountData = data
                // creditAccountData.projectIds = creditAccountData.projectIds.split(',')
                creditAccountData.oaqId = customerId
                creditAccountData.accountTypeName = date.accountType == '0' ? '长期' : '临时'
                //console.log(creditAccountData)
          
                if (describeUrl.length) {
                    describeUrl.forEach((item, index) => {
                        fileList.push({
                            status: 'done',
                            uid: index,
                            name: item.name,
                            url: item.url
                        })
                    })
                }
                setFileList(describeUrl.map((item, index) => ({...item, uid: index})))

                api.creditAdjustDetail({id: creditAccountData.id}).then(data => {
                    creditAccountData.trueAmount = data.amount //信用额度
                    creditAccountData.usedAmount = data.usedAmount //已用额度
                    // creditAccountData.company = data.company
                    creditAccountData.startDay = data.startDay
                })
                api.getTopProjectList({ isOpen: 1, companyId: search.companyId }).then(dataP => {

                    setProjects(dataP.list)
                    let newArrList = []
                    
                    dataP.list.forEach((item, index) => {
                        if (data.projectIds.indexOf(item.id) !== -1) {
                                newArrList.push(item.id)
                            }
                        })
                        setFieldsValue({
                            edit_projectid: newArrList,
                        })
                 })
            })

            await api.getCusTomInfo({relationType: customerType, relationId: customerId},true).then(data => {
                creditAccountData.oaqName = data.relationName
                creditAccountData.ctype = data.relationType
            })

            await api.getTopProjectList({ isOpen: 1, companyId: search.companyId }).then(data => {
                setProjects(data.list)
            })

            //type=1资金账户 2积分账户
            await api.getCreditAccountList({type: customerType, id: customerId}).then(data => {
                for (var i = 0; i < data.length; i++) {
                    data[i].id = data[i].companyId
                    data[i].name = data[i].companyName
                }
                setFundAccountList(data)
            })
        } else {
            editId = customerId
            creditAccountData = date
            creditAccountData.oaqId = customerId
            creditAccountData.company = search.company ? search.company : '' //账户
            creditAccountData.trueAmount = search.trueAmount ? search.trueAmount : '' //信用额度
            creditAccountData.usedAmount = search.usedAmount ? search.usedAmount : '' //已用额度
            creditAccountData.accountTypeName = date.accountType == '0' ? '长期' : '临时'
        
            await api.getCusTomInfo({relationType: customerType, relationId: customerId},true).then(data => {
                creditAccountData.oaqName = data.relationName
                creditAccountData.ctype = data.relationType
            })

            await api.getTopProjectList({ isOpen: 1, companyId: search.companyId }).then(data => {
                setProjects(data.list)
                api.getOldData({ accountType: accountType, customerId: customerId, companyId: search.companyId, customerType: customerType, id: search.ids }).then(data => {
                    // creditAccountData.trueAmount = data.totalAmount
                    // creditAccountData.projectIds = data.projectIds
                    setOldData(data.projectNames)
                    // console.log(creditAccountData)
                    setFieldsValue({ 
                      edit_projectid: data.projectIds,
                    })
                })
            })

            //type=1资金账户 2积分账户
            await api.getCreditAccountList({type: customerType, id: customerId}).then(data => {
                for (var i = 0; i < data.length; i++) {
                    data[i].id = data[i].companyId
                    data[i].name = data[i].companyName
                }
                setFundAccountList(data)
            })
        }
    }

    //计算失效日期
    const calc = (time, days, num) => {
        let dateTemp = moment(time).format('YYYY-MM-DD').split("-")
        let nDate = new Date(dateTemp[1] + "/" + dateTemp[2] + "/" + dateTemp[0])
        let millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000
        let rDate = new Date(millSeconds)
        let year = rDate.getFullYear()
        let month = rDate.getMonth() + 1
        if (month < 10) month = "0" + month
        let date = rDate.getDate()
        if (date < 10) date = "0" + date
        let newDate
        if (num) {
            newDate = year + "年" + month + "月" + date + "日"
        } else {
            newDate = year + "-" + month + "-" + date
        }
        return newDate
    }

    // 保存，调整
    const formSubmits1 = (e) => {
    
        e.preventDefault()
        validateFields((err, vals) => {
            // console.log(vals.edit_trueAmount)
            // console.log(creditAccountData.usedAmount)
            if (!err) {
                if (Number(vals.edit_trueAmount) < Number(creditAccountData.usedAmount)){
                    message.warning('调整后的额度不能小于已用额度')
                    return false
                }
                // console.log(vals)
                // console.log(calc(affectTime, vals.edit_startDay))
                if (creditAccountData.accountType == 1) {
                    // let expiryDate = calc(affectTime, +vals.edit_startDay + 1)//失效日期
                    // let arr = expiryDate.split("-")//失效日期转换格式下面和当前日期做比较
                    // let myDate = moment(new Date()).format('YYYY-MM-DD')//获取当前日期
                    // if (expiryDate < myDate) {//比较失效日期和当前日期大小
                    //     confirm({
                    //         title: '错误提示',
                    //         content: '您填写的使用期限无效（该申请审批通过后信用账户的状态为失效状态，无法使用）',
                    //         okText: '继续',
                    //         cancelText: '取消',
                    //         icon: <Icon type="close-circle" style={{color: 'red'}}/>,
                    //         onOk() {
                    //             setSaveLoading(true)
                    //             api.creditAccountAdjust({
                    //                 id: creditAccountData.id, //账户id
                    //                 accountId: accountId, //账户id
                    //                 oaqId: creditAccountData.oaqId,
                    //                 cqname: creditAccountData.oaqName,
                    //                 companyId: creditAccountData.companyId ? creditAccountData.companyId : 0,
                    //                 accountType: creditAccountData.accountType,
                    //                 addAmount: vals.addAmount,
                    //                 amount: addAfterAmount.amount,
                    //                 usedAmount: addAfterAmount.usedAmount,
                    //                 ctype: creditAccountData.ctype,
                    //                 startDay: vals.edit_startDay,
                    //                 projectIds: vals.edit_projectid,
                    //                 describeUrl: fileList,
                    //                 remark: vals.edit_remark,
                    //                 oldAmount: addAfterAmount.oldAmount,
                    //                 oldProject: oldData,
                    //             }).then(() => {
                    //                     setSaveLoading(false)
                    //                     setVisibleEdit3(false)
                    //                     message.success('操作成功')
                    //                     setTimeout(() => {
                    //                         history.push('/account/creditapply')
                    //                     }, 1000);
                    //             }).catch(() => setSaveLoading(false))
                    //         },
                    //         onCancel() {}
                    //     })
                    // } else {
                                setSaveLoading(true)
                                api.creditAccountAdjust({
                                    id: creditAccountData.id, //账户id
                                    accountId: accountId, //账户id
                                    oaqId: creditAccountData.oaqId,
                                    cqname: creditAccountData.oaqName,
                                    companyId: creditAccountData.companyId ? creditAccountData.companyId : 0,
                                    accountType: creditAccountData.accountType,
                                    addAmount: vals.addAmount,
                                    amount: addAfterAmount.amount,
                                    usedAmount: addAfterAmount.usedAmount,
                                    ctype: creditAccountData.ctype,
                                    startDay: vals.edit_startDay,
                                    projectIds: vals.edit_projectid,
                                    describeUrl: fileList,
                                    remark: vals.edit_remark,
                                    oldAmount: addAfterAmount.oldAmount,
                                    oldProject: oldData,
                                }).then(() => {
                                        setSaveLoading(false)
                                        setVisibleEdit3(false)
                                        message.success('操作成功')
                                        setTimeout(() => {
                                            history.push('/account/creditapply')
                                        }, 1000);
                                }).catch(() => setSaveLoading(false))
                    // }
                } else {
                    setSaveLoading(true)
                    api.creditAccountAdjust({
                        id: creditAccountData.id, //账户id
                        accountId: accountId, //账户id
                        oaqId: creditAccountData.oaqId,
                        cqname: creditAccountData.oaqName,
                        companyId: creditAccountData.companyId ? creditAccountData.companyId : 0,
                        accountType: creditAccountData.accountType,
                        addAmount: vals.addAmount,
                        amount: addAfterAmount.amount,
                        usedAmount: addAfterAmount.usedAmount,
                        ctype: creditAccountData.ctype,
                        startDay: vals.edit_startDay,
                        projectIds: vals.edit_projectid,
                        describeUrl: fileList,
                        remark: vals.edit_remark,
                        oldAmount: addAfterAmount.oldAmount,
                        oldProject: oldData,
                    }).then(() => {
                            setSaveLoading(false)
                            setVisibleEdit3(false)
                            message.success('操作成功')
                            setTimeout(() => {
                                history.push('/account/creditapply')
                            }, 1000);
                    }).catch(() => setSaveLoading(false))
                }
            }
        })
    }

    // 文件上传
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .pptx, .pdf',
		allowSizeType: 2
	})

    const projectsOption = projects.map((project) => (
        <Option key={project.id} value={project.id}>{project.name}</Option>
    ))

      //取消
    const onCancel = () => {
        setVisibleEdit3(false)
        history.goBack()
    }

    //新增信用额度
    const onChangeaddAmount = (e) => {
        let val = e.target.value.trim()
        setAddAfterAmountName(val)
        // console.log(val)
    }

    const onBluraddAmount = () => {
        if (addAfterAmountName == '') {
            setAddAfterAmount({})
            return message.warning('请输入新增额度')
        }
        api.getCreditAccountGetAddAfterAmount({ accountId: accountId, addAmount: addAfterAmountName }).then(res => {
            // console.log(res)
            setAddAfterAmount(res)
        }).catch(err => console.log(err))
    }

    //使用期限
    const onBlurStartDay = (e) => {
        if (accountType == 1) { //临时
            let expiryDate = calc(moment(new Date()), +e.target.value+1)//失效日期
            setLoseEfficacy(calc(moment(new Date()), +e.target.value+1, 1))
            if (expiryDate < moment(new Date()).format('YYYY-MM-DD')) {
                setColor(true)
            } else {
                setColor(false)
            }
        }
    }

    return (
        <>
            <Modal
                title="提示"
                visible={visible}
                closable={false}
                maskClosable={false}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="去申请"
                >
                <p>该客户不存在信用账户，请去“申请信用额度。”</p>
            </Modal>
            <div className="pro-detail">
                <Descriptions title={search.id > 0 ? '信用额度调整驳回重提' : '信用额度调整'} layout="horizontal "
                              className="pro-detail-title"></Descriptions>

                {
                    visibleEdit3 ? <Form onSubmit={formSubmits1}>
                        <Descriptions style={{marginBottom: 20}} title="" layout="vertical" column={4}>
                            <Descriptions.Item label="OAID/QID">{creditAccountData.oaqId}</Descriptions.Item>
                            <Descriptions.Item label="客户/代理商名称">{creditAccountData.oaqName}</Descriptions.Item>
                            <Descriptions.Item label="账户">{creditAccountData.company}</Descriptions.Item>
                            <Descriptions.Item label="账户类型">{creditAccountData.accountTypeName}</Descriptions.Item>
                        </Descriptions>

                        <Row gutter={24}>
                            {/*<Descriptions title="" layout="vertical" column={4}>*/}
                            <Col span={6}>
                                {/* <Form.Item label="信用额度(调整后总额度)">
                                    {getFieldDecorator('edit_trueAmount', {
                                        initialValue: creditAccountData.trueAmount,
                                        rules: [{required: true, message: '请输入信用额度'}]
                                    })(
                                        <Input placeholder="请输入"/>)}
                                </Form.Item> */}
                                <Form.Item label="新增额度">
                                    {getFieldDecorator('addAmount', {
                                        initialValue: creditAccountData.addAmount,
                                        rules: [{required: true, message: '请输入新增额度'}]
                                    })(
                                        <Input placeholder="请输入" onChange={onChangeaddAmount} onBlur={onBluraddAmount}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="调整前额度">{addAfterAmount.oldAmount}</Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="调整后额度">{addAfterAmount.amount}</Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="已用额度">{addAfterAmount.usedAmount}</Form.Item>
                            </Col>
                            {/* {accountType == 1 && } */}
                            {/*</Descriptions>*/}
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label="使用期限（天）">
                                    {getFieldDecorator('edit_startDay', {
                                        initialValue: creditAccountData.startDay,
                                        rules: [{required: true, message: '请输入使用期限'},{ 
                                        // rules: [{required: accountType == 1 ? true : false, message: '请输入使用期限'},{ 
                                          pattern: /^[1-9]\d*$/, message: '请输入正整数'
                                        }
                                    ]
                                    })(
                                        <Input
                                            // onBlur={onBlurStartDay} 临时才有用
                                            placeholder="请输入"/>)}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="信用账户ID">{accountId}</Form.Item>
                            </Col>
                            <Col span={6}>
                                {
                                    accountType == 1 ? <Form.Item label="信用账户生效日期">{'未生成'}</Form.Item> :
                                        <Form.Item label="信用账户生效日期">{moment(affectTime).format(`YYYY${'年'}MM${'月'}DD${'日'}`)}</Form.Item>
                                }
                            </Col>
                            {accountType == 1 && <Col span={6}>
                                {/* <Form.Item label="信用账户失效日期"><div style={{ color: color ? 'red' : ''}}>{loseEfficacy}</div></Form.Item>     */}
                                <Form.Item label="信用账户失效日期">{'未生成'}</Form.Item>    
                            </Col>}
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item label="关联项目">
                                    {getFieldDecorator('edit_projectid', {
                                        initialValue: creditAccountData.projectIds,
                                        rules: [{required: true, message: '请输入关联项目'}]
                                    })(
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            placeholder="请选择关联项目"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}
                                        >
                                            {projectsOption}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        {accountType == 1 &&
                            <p style={{ color: 'red', fontSize: '12px', fontWeight: 500 }}>
                                提示：临时信用额度调额后失效日期 = 该临时信用额度调额审批通过的日期 + 使用期限 +1，该临时信用额度关联的信用订单逾期日 = 该信用订单生效日期 + 使用期限 +1
                            </p>
                        }
                        <Descriptions title="" layout="vertical">
                            <Descriptions.Item label="附件" visible={visibledraggerRef}>
                                <div className="dragger-box" ref={draggerRef}>
                                    <Dragger
                                        {...uploadFiles}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox"/>
                                        </p>
                                        <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                        <p className="ant-upload-hint">
                                            支持扩展名：.gif、png、jpg、pptx、pdf 单个文件大小不超过5M，不超过5个附件。
                                        </p>
                                    </Dragger>
                                </div>
                            </Descriptions.Item>
                        </Descriptions>

                        <Descriptions title="" layout="vertical">
                            <Descriptions.Item label="备注">
                                {getFieldDecorator('edit_remark', {initialValue: creditAccountData.remark})(
                                    <TextArea disabled={!canEdit} cols={150} rows={4}></TextArea>
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                        <BtnGroup onCancel={onCancel} loading={saveLoading}/>
                    </Form> : <div></div>
                }
            </div>
        </>
    )
}

export default Form.create()(CreditApplyEdit)