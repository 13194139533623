/**
 * 模块名称: 绩效管理模块
 * @author yangzhihang@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AchReault from './AchResult'
import YearTarget from './YearTarget'
import './assets/style.scss'

const Index = ({ match, history }) => {
  return (
    <div>
      <Switch>
        <Route path={match.path + '/achresult'} exact component={AchReault} />
        <Route path={match.path + '/yeartarget'} exact component={YearTarget} />
        <Route component={AchReault} />
      </Switch>
    </div>
  )
}

export default Index