/**
 * 模块名称: 代理商积分列表
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  Modal,
  Popconfirm,
  DatePicker,
  Switch
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import moment from 'moment'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

let filter = {
  companyId: ''
}

let params = {}

const AgentIntegralList = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  // const [authList, setAuthList] = useState([])
  const [bizTypeList, setBizTypeList] = useState([])
  const [approvalStatusAll, setApprovalStatusAll] = useState([])
  const [expand, setExpand] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [flag, setFlag] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      companyId: '',
      qId: '',
      qName: '',
      approvalStatus: '',
      bizNo: '',
      bizType: '',
      applyStaffName: '',
      updateTimeMin: '',
      updateTimeMax: '',
    }
    shouldMe = null
    apiOperateTimer = true
    getList()
    onsetBiztypeList()
    onsetApprovalStatus()
    // api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getList = () => {
    if(apiOperateTimer){
      setLoading(true)
      if(shouldMe){
        filter.shouldMe = shouldMe
      }else{
        for(let i in filter){
          if(i == 'shouldMe')delete filter[i]
        }
      }
      api.getAgentIntegralList({ ...filter, limit: pageSize, page: currentPage, type: 2 })
      .then(data => {
        setLoading(false)
        for(var i = 0; i < data.list.length; i++){
          //data.list[i].operateAuth = ['edit', 'delete']
        }
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getList()
  }

  // 业务类型
  const onsetBiztypeList = () => {
    var tmplist = new Array()
    tmplist.push({id:0, name:'充积分'})
    tmplist.push({id:1, name:'退积分'})
    setBizTypeList(tmplist)
  }

  // 审批状态
  const onsetApprovalStatus = () => {
    api.getCommonWxTab({ dataType: '2', pageType: '2' }).then(data => {
      setApprovalStatusAll(data.list)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        filter = { ...filter, ...vals }
        history.replace(match.path)
        currentPage = 1
        getList()
      }
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onChangeCorp = (data) => {
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    shouldMe = null
    setStatusValName(undefined)
    setStatusVal(undefined)
    currentPage = 1
    filter = {
      companyId: '',
      qId: '',
      qName: '',
      approvalStatus: '',
      bizNo: '',
      bizType: '',
      applyStaffName: '',
      updateTimeMin: '',
      updateTimeMax: '',
    }
    history.replace(match.path)
    getList()
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }
  
  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const onChecked = (value,val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[value].content

    setFieldsValue({
      companyId: data.companyId ? data.companyId : undefined,
      qId: data.qId ? data.qId : undefined,
      qName: data.qName ? data.qName : undefined,
      approvalStatus: data.approvalStatus ? data.approvalStatus : undefined,
      bizNo: data.bizNo ? data.bizNo : undefined,
      bizType: data.bizType ? data.bizType : undefined,
      applyStaffName: data.applyStaffName ? data.applyStaffName : undefined,
      updateTime: data.updateTime ? [moment(data.updateTime[0], 'YYYY-MM-DD'), moment(data.updateTime[1], 'YYYY-MM-DD')] : undefined
    })
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'updateTime') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                params[k] = vals[k]
              }
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  //编辑
  const onEdit = (date) => {
    history.push('/agent/integralManage/edit/'+date.bizType+'?id='+date.id)
  }

  const onDelete = (id) => {
    api.delAgentIntegral({
      id: id
    }).then(res => {
      message.success('删除成功')
      getList()
    })
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.updateTimeMin = ''
      filter.updateTimeMax = ''
    } else {
      filter.updateTimeMin = moment(data[0]).format('YYYY-MM-DD')
      filter.updateTimeMax = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <div className="table-public">
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
              <Row gutter={24}>
                <Col span={6}>
                  <FormItem label="QID" labelCol={{span: 4}}>
                    {getFieldDecorator('qId')(<Input placeholder="请输入" />)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="代理商名称" labelCol={{span: 8}}>
                    {getFieldDecorator('qName')(<Input placeholder="请输入" />)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="状态" labelCol={{span: 4}}>
                    {getFieldDecorator('approvalStatus')(
                      <Select placeholder="请选择">
                        {approvalStatusAll.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="业务编号" labelCol={{span: 8}}>
                    {getFieldDecorator('bizNo')(<Input placeholder="请输入" />)}
                  </FormItem>
                </Col>
                </Row>
              <Row gutter={24} className={`search-btns ${expand ? '' : 'hide'}`}>
                <Col span={7} >
                  <FormItem label="业务类型" labelCol={{span: 6}}>
                    {getFieldDecorator('bizType')(
                      <Select placeholder="请选择">
                        {bizTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col span={7}>
                  <FormItem label="申请人" labelCol={{span: 6}}>
                    {getFieldDecorator('applyStaffName')(<Input placeholder="请输入" />)}
                  </FormItem>
                </Col>
                <Col span={10}>
                  <FormItem label="最后处理时间">
                    {getFieldDecorator('updateTime')(<RangePicker onChange={onChangePicker}  allowClear={false} span={12} style={{ width: '100%' }} />)}
                  </FormItem>
                </Col>
              </Row>
            </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns">

        <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={(e) => onChangeGetFilter(e)}
                onSelect={onChecked}
              >
                {
                  filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                  )
                }
              </Select>
            </div>
            <Button className="filter-savebtn" style={{marginLeft: 8, marginRight: 8 }}  onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8, marginBottom: 20 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <Alert className="count-alert" message={
        <>
          <span>总共{count}条数据</span>
        </>
      }  type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 1400}}
        onChange={onChangeTable}
        // rowSelection={false}
      >
        <Column title="序号" dataIndex="id" fixed="left" width={80} />  
        <Column title="业务编号" dataIndex="bizNo" fixed="left" width={150}  />
        <Column title="QID" dataIndex="qId" fixed="left" width={80} />
        <Column title="代理商名称" 
         dataIndex="qName"
         fixed="left"
         width={240}
         ellipsis={true}
         render={(text, record) =>
            <Link to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
        }  />
        <Column title="业务类型" dataIndex="bizTypeCn" />
        <Column title="主体" dataIndex="companyName" />
        <Column title="项目" dataIndex="projectName" />
        <Column title="积分（数量）" dataIndex="num" render={(text, record) =>
          <>
            {record.bizType == 0 ?
              <font color="green">+{text}</font>
              :
              <font color="red">-{text}</font>
            }
          </>
        }   />
        <Column title="状态" dataIndex="approvalStatusCn"  />
        <Column title="最后处理时间" dataIndex="updateTime" />
        <Column title="申请人" dataIndex="applyStaffName" />
        <Column title="操作"
          key="set" 
          fixed="right"
          width={100}
          render={(text, record) =>
            <>
              <Auth auths={text.operateAuth} code="edit">
                <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} />
              </Auth>
              {' '}
              <Auth auths={text.operateAuth} code="delete">
                <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                  <Icon className="operate-icon" style={{ color: 'red' }} type="delete" />
                </Popconfirm>
              </Auth>
            </>
          }
        />
      </Table>
    </div>
  )
}

export default Form.create()(AgentIntegralList)