/**
 * 模块名称: 404页
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'

const NoMatch = (props) => (
    <Result
        status="404"
        title="404"
        subTitle="抱歉, 这个页面不存在"
        extra={<Link to="/index">回到首页</Link>}
    />
)

export default NoMatch