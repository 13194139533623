/**
 * 模块名称: 代理商调整积分申请
 * @author xuxiaobo@372163.com
 */

import React, {useEffect, useRef, useState} from 'react'
import {Col, Descriptions, Form, Icon, Input, message, Row, Select, Upload} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'
import {useSelector} from "react-redux";

const {TextArea} = Input
const {Dragger} = Upload;
const Option = Select.Option

let fundAccountData = {}

const AgentIntegralAdd = (props) => {
    const {history} = props
    const {getFieldDecorator, validateFields} = props.form
    const search = parseSearch(props.location.search)
    const [detailData, setDetailData] = useState({})
    const [saveLoading, setSaveLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const draggerRef = useRef(null)
    const [fundAccountList, setFundAccountList] = useState([])
    const userInfo = useSelector(state => state.userInfo)

    useEffect(() => {
        fundAccountData = {}
        var data = {}
        data.bizType = search.bizType == 1 ? search.bizType : '0'
        data.relationId = search.relationId
        data.bizTypeCn = data.bizType == 1 ? '申请退积分' : '申请充积分'
        data.integral = 0
        setDetailData(data)

        //relationType=0直销,1代理商
        api.getCusTomInfo({relationType: 1, relationId: data.relationId}).then(tmps => {
            data.relationName = tmps.relationName
            setDetailData(data)
        })

        //type=1资金账户 2积分账户 relationType=0直销,1代理商
        api.getFundAccountList({relationType: 1, customerId: data.relationId, type: 2}).then(tmps => {
            setFundAccountList(tmps.list)
        })
    }, [])

    // 保存，申请
    const formSubmits = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {

            if (!err) {
                setSaveLoading(true)
                var tmpdata = {
                    companyId: fundAccountData.companyId,
                    projectId: fundAccountData.projectId,
                    relationId: detailData.relationId,
                    bizType: detailData.bizType,
                    num: vals.edit_num,
                    remark: vals.edit_remark,
                    attachments: fileList,
                }

                api.saveAgentIntegral(tmpdata)
                    .then(() => {
                        setSaveLoading(false)
                        message.success('操作成功')
                        setTimeout(() => {
                            history.push('/agent/integralManage')
                        }, 1000);
                    })
                    .catch(() => setSaveLoading(false))
            }
        })
    }

    // 文件上传
    const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: ".rar, .zip, .doc, .docx, .pdf, .jpg, .png, .jpeg, .bmp",
		allowSizeType: 2
	})

    const changeFundList = (value, option) => {
        fundAccountData = JSON.parse(option.props['data-json']);
    }

    return (
        <>
            <div className="pro-detail">
                <Descriptions title={detailData.bizTypeCn} layout="horizontal "
                              className="pro-detail-title"></Descriptions>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="QID">{detailData.relationId}</Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="代理商名称">{detailData.relationName}</Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="申请人">{ userInfo.staffName}</Form.Item>
                    </Col>
                </Row>

                <Form onSubmit={formSubmits}>
                    <Row gutter={20}>
                        <Col span={14}>
                            <Form.Item label="主体/项目">
                                {getFieldDecorator('edit_companyId', {
                                    initialValue: detailData.companyId,
                                    rules: [{required: true, message: '请输入主体/项目'}]
                                })(
                                    <Select
                                        showSearch
                                        optionFilterProp={'data-value'}
                                        placeholder="请选择"
                                        style={{width: 450}}
                                        onChange={(value, option) => changeFundList(value, option)}
                                    >
                                        {fundAccountList.map(item =>
                                            <Option key={item.id}
                                                    value={item.id}
                                                    data-value={`${item.companyName}_${item.projectName}`}
                                                    data-json={JSON.stringify(item)}
                                            >{item.companyName}（项目：{item.projectName}）</Option>)}
                                    </Select>
                                )}
                                &nbsp;&nbsp;&nbsp;
                                <>可用积分: {fundAccountData.integral ? fundAccountData.integral : '0.00'}</>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            {detailData.bizType == 0 ?
                                <Form.Item label="充积分数量">
                                    {getFieldDecorator('edit_num', {
                                        rules: [{required: true, message: '请输入充积分数量'},{ 
                                            pattern: /^[1-9]\d*$/, message: '请输入正整数'
                                        }]
                                    })(<Input placeholder="请输入"/>)}
                                </Form.Item>
                                :
                                <Form.Item label="退积分数量">
                                    {getFieldDecorator('edit_num', { 
                                        rules: [{required: true, message: '请输入退积分数量'},{ 
                                            pattern: /^[1-9]\d*$/, message: '请输入正整数'
                                        }]
                                    })(<Input placeholder="请输入"/>)}
                                </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Descriptions title="" layout="vertical">
                        <Form.Item label="附件">
                            <div className="dragger-box" ref={draggerRef}>
                                <Dragger
                                    {...uploadFiles}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <Icon type="inbox"/>
                                    </p>
                                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                    <p className="ant-upload-hint">
                                        支持扩展名：.rar、zip、doc、docx、pdf、jpg, .png, .jpeg, .bmp 单个文件大小不超过5M，不超过5个附件。
                                    </p>
                                </Dragger>
                            </div>
                        </Form.Item>
                    </Descriptions>
                    <Row gutter={10}>
                    <Col span={9}>
                    <Form.Item label="备注">
                        {getFieldDecorator('edit_remark', {initialValue: detailData.remark})(
                            <TextArea maxLength={100} rows={4}></TextArea>
                        )}
                    </Form.Item>
                    </Col>
                    </Row>

                    <BtnGroup onCancel={() => history.push('/agent/integralManage')} loading={saveLoading}/>
                </Form>
            </div>
        </>
    )
}

export default Form.create()(AgentIntegralAdd)